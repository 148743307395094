import Popup from '^/components/molecules/Popup';
import { usePopupStore } from '^/store/popup';
import theme from '^/theme';
import * as T from '^/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import ChangeCoverImage from './ChangeCoverImage';
import { UseFormSetValue } from 'react-hook-form';
import { AddProjectFormDataTypes, rolesData } from './addProjectFormUtils';
import ChangeProjectLogo from './ChangeProjectLogo';
import RoleInformation from './RoleInformation';
import { useL10n } from '^/hooks';
import Text from './text';

const Container = styled.div({
  padding: '0px 20px',
  borderRadius: '8px',
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ContentContainer = styled.div({
  padding: '0px 0px 20px 0px',
  borderTop: `1px solid ${theme.colors.coolGray[100]}`,
  borderBottom: `1px solid ${theme.colors.coolGray[100]}`,
});

interface ProjectPopupProps {
  setValue: UseFormSetValue<AddProjectFormDataTypes>;
}

const ProjectPopup = ({ setValue }: ProjectPopupProps) => {
  const [l10n] = useL10n();
  const setPopup = usePopupStore(s => s.setMinorPopup);
  const projectPopupType = usePopupStore(s => s.popupOptions.projectPagePopupType);

  const handleClose = () => {
    setPopup(undefined);
  };

  const content = useMemo(() => {
    switch (projectPopupType) {
      case T.ProjectPagePopupType.PROJECT_COVER_IMAGE:
        return {
          title: l10n(Text.changeCoverImage),
          content: <ChangeCoverImage setValue={setValue} />,
        };
      case T.ProjectPagePopupType.PROJECT_LOGO_IMAGE:
        return {
          title: l10n(Text.changeLogoImage),
          content: <ChangeProjectLogo setValue={setValue} />,
        };
      case T.ProjectPagePopupType.PROJECT_ROLE_INFORMATION:
        return {
          title: l10n(Text.userRolesAndPermissions),
          content: <RoleInformation roles={rolesData} />,
        };
      default:
        return {
          title: '',
          content: null,
        };
    }
  }, [projectPopupType, l10n]);

  return (
    <Popup
      title={content.title}
      alpha={0.45}
      zIndex={900}
      onCloseClick={handleClose}
      headerStyle={{ padding: '20px 20px' }}
      modalRootContainerStyles={{ width: '365px' }}
    >
      <Container>
        <ContentContainer>{content.content}</ContentContainer>
      </Container>
    </Popup>
  );
};

export default ProjectPopup;
