/* eslint-disable max-lines */
import Tippy from '@tippyjs/react';
import React, { FC, MouseEvent, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import CheckSvg from '^/assets/icons/contents-list/check.svg';
import DisabledUncheckSvg from '^/assets/icons/contents-list/disabled-uncheck.svg';
import FailedStatusSvg from '^/assets/icons/contents-list/red-alert.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import { useThreeStore } from '^/components/three/ThreeStore';
import { UseL10n, UseShouldContentDisabled, useL10n, useShouldContentDisabled } from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { useIssueContents } from '^/hooks/useIssueContents';
import { PatchContent, PatchGCPContent, contentsSelector } from '^/store/duck/Contents';
import {
  ChangeEditingContent,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  OpenContentPagePopup,
} from '^/store/duck/Pages';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import { useFlightScheduleStore } from '^/store/flightScheduleStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import * as T from '^/types';
import { getSingleContentId } from '^/utilities/state-util';
import LoadingIcon, { Props as LoadingIconProps } from '../LoadingIcon';
import Text from './text';
import { useSnappingStore } from '^/store/snapping/snappingStore';

const isReallyShowChecked: (args: {
  type: T.ContentType;
  in3D: boolean;
  in3DPointCloud: boolean;
  is3DMeshSelected: boolean;
}) => boolean = ({ type, in3D, in3DPointCloud, is3DMeshSelected }) => {
  switch (type) {
    case T.ContentType.MAP:
    case T.ContentType.DSM:
    case T.ContentType.DTM:
      return !in3D && !in3DPointCloud;
    case T.ContentType.GCP_GROUP:
      return !in3DPointCloud;
    case T.ContentType.THREE_D_ORTHO:
      return in3D && !in3DPointCloud && !is3DMeshSelected;
    case T.ContentType.THREE_D_MESH:
      return in3D && !in3DPointCloud && is3DMeshSelected;
    case T.ContentType.POINTCLOUD:
      return in3D && in3DPointCloud && !is3DMeshSelected;
    default:
      return true;
  }
};

const RelativeDiv = styled.div({ position: 'relative' });
export const CheckBox = styled.div<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: !isDisabled ? '#CED7E5' : undefined,
  },
}));

const FailedStatusIcon = styled(FailedStatusSvg)({
  marginRight: '1px',
});

const loadingDivCustomStyle: LoadingIconProps['loadingDivCustomStyle'] = {
  width: '14px',
  height: '14px',
};

export interface Props {
  readonly content: T.Content;
  readonly isProcessingOrFailed: boolean;
  readonly isFailed: boolean;
  readonly trackAction?: string;
  readonly trackLabel?: string;
}

export const ContentsListItemCheckbox: FC<Props> = ({
  content,
  isProcessingOrFailed: isProcessing,
  isFailed,
  trackAction,
  trackLabel,
}) => {
  const [l10n]: UseL10n = useL10n();
  const dispatch: Dispatch = useDispatch();

  const { toggleSnapEnabledDxfIds, snapEnabledDxfIds } = useSnappingStore();

  const in3D = useSelector((s: T.State) => s.Pages.Contents.in3D);
  const in3DPointCloud = useSelector((s: T.State) => s.Pages.Contents.in3DPointCloud);
  const isInDashboard = useSelector(
    (s: T.State) => s.Pages.Contents.sidebarTab === T.ContentPageTabType.DASHBOARD
  );

  const { updateESSContent } = useESSContents();
  const updateFlightPlans = useFlightPlanStore(s => s.updateFlightPlans);
  const updateFlightSchedules = useFlightScheduleStore(s => s.updateFlightSchedules);

  const { patchIssueContentSelection } = useIssueContents();
  const byId = useContentsStore(s => s.contents.byId);

  const shouldContentDisabled: UseShouldContentDisabled = useShouldContentDisabled(content.type);
  const contentId: T.Content['id'] = content.id;

  const editingContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);
  const isESS = content.category === T.ContentCategory.ESS;

  const dsmId: T.DSMContent['id'] | undefined = useSelector((s: T.State) =>
    getSingleContentId(s.Pages, s.ProjectConfigPerUser, T.ContentType.DSM)
  );
  const dtmId: T.DSMContent['id'] | undefined = useSelector((s: T.State) =>
    getSingleContentId(s.Pages, s.ProjectConfigPerUser, T.ContentType.DTM)
  );

  const dsmContent: T.DSMContent | undefined = byId[dsmId ?? NaN] as T.DSMContent | undefined;
  const dtmContent: T.DTMContent | undefined = byId[dtmId ?? NaN] as T.DTMContent | undefined;

  const isSelected = useMemo(() => {
    switch (content.type) {
      case T.ContentType.DSM:
      case T.ContentType.DTM:
        return Boolean(dsmContent?.config?.selectedAt || dtmContent?.config?.selectedAt) ?? false;

      default: {
        return Boolean(content?.config?.selectedAt) ?? false;
      }
    }
  }, [
    content.config?.selectedAt,
    editingContentId,
    dtmContent?.config?.selectedAt,
    dsmContent?.config?.selectedAt,
  ]);

  const threeDMeshId: T.ThreeDMeshContent['id'] | undefined = useSelector((s: T.State) =>
    getSingleContentId(s.Pages, s.ProjectConfigPerUser, T.ContentType.THREE_D_MESH)
  );

  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);

  const { is3DMeshSelected, isDTMMapSelected, isDSMMapSelected } = (() => ({
    is3DMeshSelected: contentsSelector.isSelected(ProjectConfigPerUser, isESS)(threeDMeshId),
    isDTMMapSelected: contentsSelector.isSelected(ProjectConfigPerUser)(dtmId),
    isDSMMapSelected: contentsSelector.isSelected(ProjectConfigPerUser)(dsmId),
  }))();

  const isReallyDisplayChecked: boolean = isReallyShowChecked({
    type: content.type,
    in3D,
    in3DPointCloud,
    is3DMeshSelected,
  });

  const isShowChecked: boolean = isSelected && isReallyDisplayChecked;

  const ifcLoadedContent = useThreeStore(s => s.ifcLoadedContent);
  const setifcLoadedContent = useThreeStore(s => s.setifcLoadedContent);

  function selectMapItem(selectContentType: T.ContentType): void {
    const selectContentId: T.PointCloudContent['id'] | T.ThreeDOrthoContent['id'] | undefined =
      getSingleContentId(Pages, ProjectConfigPerUser, selectContentType);

    if (!selectContentId) {
      return;
    }

    dispatch(
      PatchContent({
        content: {
          id: selectContentId,
          config: {
            selectedAt: new Date(),
          },
        },
      })
    );

    if (selectContentType === T.ContentType.DSM && dtmId) {
      if (isDTMMapSelected) {
        dispatch(
          PatchContent({
            content: {
              id: dtmId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
    }
    if (selectContentType === T.ContentType.DTM && dsmId) {
      if (isDSMMapSelected) {
        dispatch(
          PatchContent({
            content: {
              id: dsmId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
    }
  }

  function unSelectMapItem(anotherContentType: T.Content['type']): void {
    const anotherContentId: T.Content['id'] | undefined = getSingleContentId(
      Pages,
      ProjectConfigPerUser,
      anotherContentType
    );
    if (anotherContentId === undefined) {
      return;
    }

    const isAnotherContentSelected: boolean = contentsSelector.isSelected(
      ProjectConfigPerUser,
      isESS
    )(anotherContentId);

    if (isAnotherContentSelected) {
      dispatch(
        PatchContent({
          content: {
            id: anotherContentId,
            config: {
              selectedAt: undefined,
            },
          },
        })
      );
    }
  }

  function selectUnselectContents({
    typesToSelect,
    typesToUnselect,
  }: {
    typesToSelect: T.ContentType[];
    typesToUnselect: T.ContentType[];
  }) {
    typesToSelect.forEach(selectMapItem);
    typesToUnselect.forEach(unSelectMapItem);
  }

  const handleSelect: (e: MouseEvent) => void = e => {
    e.stopPropagation();
    if (shouldContentDisabled) {
      return;
    }

    if (isFailed) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.PROGRESS_BAR,
          contentId,
        })
      );

      return;
    }

    if (isProcessing) {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.PROGRESS_BAR,
          contentId,
        })
      );

      return;
    }

    switch (content.type) {
      case T.ContentType.GCP_GROUP:
        dispatch(
          PatchGCPContent({
            content: {
              id: content.id,
              config: {
                type: content.type,
                selectedAt: isSelected ? undefined : new Date(),
              },
            },
          })
        );
        break;
      case T.ContentType.ESS_MODEL:
      case T.ContentType.ESS_MODEL_CUSTOM:
      case T.ContentType.ESS_ARROW:
      case T.ContentType.ESS_POLYLINE:
      case T.ContentType.ESS_POLYGON:
      case T.ContentType.ESS_LINE_TEXT:
      case T.ContentType.ESS_TEXT: {
        void updateESSContent({
          content: {
            id: contentId,
            config: {
              selectedAt: isSelected ? undefined : new Date(),
            },
          },
          skipDBUpdate: true,
        });
        break;
      }
      case T.ContentType.FLIGHT_PLAN: {
        updateFlightPlans({
          ...content,
          config: {
            type: T.ContentType.FLIGHT_PLAN,
            selectedAt: isSelected ? undefined : new Date(),
          },
        });
        break;
      }
      case T.ContentType.FLIGHT_SCHEDULE: {
        updateFlightSchedules({
          ...content,
          id: contentId,
          config: {
            type: T.ContentType.FLIGHT_SCHEDULE,
            selectedAt: isSelected ? undefined : new Date(),
          },
        });
        break;
      }
      case T.ContentType.ISSUE_PHOTO:
      case T.ContentType.ISSUE_POINT: {
        patchIssueContentSelection({
          ...content,
          id: contentId,
          config: {
            type: content.type,
            selectedAt: isSelected ? undefined : new Date(),
          },
        } as T.IssueContent);
        break;
      }
      case T.ContentType.BIM: {
        if (isSelected) {
          const temp = ifcLoadedContent.filter(val => val.contentId !== contentId);
          setifcLoadedContent(temp);
        }
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                selectedAt: isSelected ? undefined : new Date(),
              },
            },
          })
        );
        break;
      }

      case T.ContentType.DSM:
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                selectedAt: isSelected ? undefined : new Date(),
              },
            },
          })
        );
        if (dtmContent) {
          dispatch(
            PatchContent({
              content: {
                id: dtmContent.id,
                config: {
                  selectedAt: undefined,
                },
              },
            })
          );
        }
        break;
      case T.ContentType.LENGTH:
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                selectedAt: isSelected ? undefined : new Date(),
                ...(isSelected && { isElevationToggled: false }),
              },
            },
          })
        );
        break;
      case T.ContentType.DESIGN_DXF:
      case T.ContentType.BLUEPRINT_DWG:
      case T.ContentType.BLUEPRINT_DXF:
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                selectedAt: isSelected ? undefined : new Date(),
                ...(isSelected && { isElevationToggled: false }),
              },
            },
          })
        );
        if (snapEnabledDxfIds.includes(contentId) && isSelected) {
          toggleSnapEnabledDxfIds(contentId);
        }

        break;
      default: {
        dispatch(
          PatchContent({
            content: {
              id: contentId,
              config: {
                selectedAt: isSelected ? undefined : new Date(),
              },
            },
          })
        );
      }
    }

    if (!isSelected) {
      /**
       * @todo This switch should be in the correct component
       */
      switch (content.type) {
        case T.ContentType.MARKER:
        case T.ContentType.LENGTH:
        case T.ContentType.AREA:
        case T.ContentType.THREE_AREA:
        case T.ContentType.VOLUME: {
          const isProcessingOrFailed: boolean =
            contentsSelector.isProcessingOrFailedByContent(content);

          dispatch(
            ChangeEditingContent({
              contentId,
              skipSelecting: isProcessingOrFailed,
              skipSwitchTab: isInDashboard,
            })
          );
          break;
        }
        case T.ContentType.MAP:
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.THREE_D_ORTHO,
            ],
          });
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (in3D) {
            dispatch(ChangeIn3D({ in3D: false, transition: false })); //transition: true was not switching the map back to 2D by clicking the checkbox
          }
          break;

        case T.ContentType.DSM:
          selectUnselectContents({
            typesToSelect: [T.ContentType.MAP],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.THREE_D_ORTHO,
            ],
          });
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (in3D) {
            dispatch(ChangeIn3D({ in3D: false }));
          }
          break;

        case T.ContentType.DTM:
          selectUnselectContents({
            typesToSelect: [T.ContentType.MAP],
            typesToUnselect: [
              T.ContentType.THREE_D_ORTHO,
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
            ],
          });
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (in3D) {
            dispatch(ChangeIn3D({ in3D: false }));
          }
          break;

        case T.ContentType.GCP_GROUP:
        case T.ContentType.ISSUE_POINT:
        case T.ContentType.ISSUE_PHOTO:
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          break;

        case T.ContentType.THREE_D_ORTHO:
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.MAP,
              T.ContentType.DSM,
              T.ContentType.DTM,
            ],
          });
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (!in3D) {
            dispatch(ChangeIn3D({ in3D: true }));
          }
          break;

        case T.ContentType.THREE_D_MESH:
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_ORTHO,
              T.ContentType.POINTCLOUD,
              T.ContentType.MAP,
              T.ContentType.DSM,
              T.ContentType.DTM,
            ],
          });
          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (!in3D) {
            dispatch(ChangeIn3D({ in3D: true }));
          }
          break;

        case T.ContentType.POINTCLOUD:
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_ORTHO,
              T.ContentType.THREE_D_MESH,
              T.ContentType.MAP,
              T.ContentType.DSM,
              T.ContentType.DTM,
            ],
          });
          if (!in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: true }));
          }
          if (!in3D) {
            dispatch(ChangeIn3D({ in3D: true }));
          }
          break;
        default:
      }
    }
  };

  const alternativeIcon: ReactNode = isFailed ? (
    <FailedStatusIcon />
  ) : isProcessing ? (
    <LoadingIcon loadingDivCustomStyle={loadingDivCustomStyle} />
  ) : undefined;

  const checkBoxSvg: ReactNode = isShowChecked ? <CheckSvg /> : <UncheckSvg />;
  const checkBox: ReactNode = isProcessing ? (
    <LoadingIcon loadingDivCustomStyle={loadingDivCustomStyle} />
  ) : (
    <CheckBox isDisabled={shouldContentDisabled} onClick={handleSelect}>
      {shouldContentDisabled ? <DisabledUncheckSvg /> : checkBoxSvg}
    </CheckBox>
  );

  const checkboxTooltipText: string = l10n(
    isShowChecked ? Text.tooltipUnselectText : Text.tooltipSelectText
  );
  const result: ReactNode =
    alternativeIcon !== undefined ? (
      alternativeIcon
    ) : (
      <RelativeDiv>
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="top"
          arrow={false}
          content={checkboxTooltipText}
          disabled={shouldContentDisabled}
        >
          <CheckBox
            isDisabled={shouldContentDisabled}
            onClick={handleSelect}
            data-ddm-track-action={trackAction}
            data-ddm-track-label={trackLabel}
            data-testid={`content-checkbox-${content.type}`}
            data-state={isSelected ? 'checked' : 'unchecked'}
          >
            {checkBox}
          </CheckBox>
        </Tippy>
      </RelativeDiv>
    );

  return <>{result}</>;
};
