import React from 'react';

import styled from 'styled-components';
import { UseFormSetValue } from 'react-hook-form';

import theme from '^/theme';
import Button from '^/components/atoms/Button';
import ButtonRow from '^/components/molecules/ButtonRow';

import CameraIcon from '^/assets/icons/camera.svg';
import CloseSVG from '^/assets/icons/close-gray.svg';
import UploadSVG from '^/assets/icons/photo/upload.svg';

import { AddProjectFormDataTypes } from './addProjectFormUtils';
import { usePopupStore } from '^/store/popup';
import { useL10n } from '^/hooks';
import Text from './text';

const ImageUploadArea = styled.div({
  width: '100%',
  height: '200px',
  border: '1px dashed #ccc',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: '#fafafa',

  '&:hover': {
    borderColor: '#007AFF',
    backgroundColor: '#f0f9ff',
  },
});

const CoverImageUploadArea = styled(ImageUploadArea)({
  height: '120px',
  width: '276px',
  position: 'relative',
});

const HiddenInput = styled.input({
  display: 'none',
});

const UploadLabel = styled.label({
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#f0f9ff',
  },
});

const PreviewWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const ImagePreview = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
});

const LogoPreview = styled(ImagePreview)({
  borderRadius: '4px',
});

const UploadText = styled.div({
  fontSize: '12px',
  color: '#666',
  marginTop: '8px',
  textAlign: 'center',
});
const FileTypeText = styled.div({
  fontSize: '12px',
  padding: '4px',
  color: theme.colorTokens.textCool,
  marginTop: '8px',
  textAlign: 'center',
  fontWeight: 'bold',
  background: theme.colorTokens.bgLightCoolGray,
});

const ErrorText = styled.div({
  color: '#ff4d4f',
  fontSize: '12px',
  marginTop: '4px',
  textAlign: 'center',
});

const ButtonContainer = styled.div({
  display: 'flex',
  gap: '8px',
  marginTop: '16px',
  marginBottom: '16px',
  justifyContent: 'center',
});

const Wrapper = styled.div({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const UploadIcon = styled(UploadSVG)({
  marginRight: '8px',
  '& svg': {
    width: '16px',
    height: '16px',
  },
  '& path': {
    fill: theme.colorTokens.textCool,
  },
});

const CloseIcon = styled(CloseSVG)({
  marginRight: '8px',
  '& path': {
    fill: theme.colorTokens.textCool,
  },
});

interface ChangeCoverImageProps {
  setValue: UseFormSetValue<AddProjectFormDataTypes>;
  thumbnailPreview?: string;
}

const MAX_FILE_SIZE = 2 * 1024 * 1024;

export default function ChangeCoverImage({
  setValue,
  thumbnailPreview = '',
}: ChangeCoverImageProps) {
  const [l10n] = useL10n();
  const [error, setError] = React.useState<string>('');
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [logoPreview, setLogoPreview] = React.useState<string>(thumbnailPreview);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const setPopup = usePopupStore(s => s.setMinorPopup);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setError('');

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(l10n(Text.imageSizeError));
        e.target.value = '';
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setLogoPreview(result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setLogoPreview('');
    setSelectedFile(null);
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleApply = () => {
    setValue('thumbnail', selectedFile);
    setPopup(undefined);
  };
  const handleCancel = () => {
    setValue('thumbnail', null);
    setPopup(undefined);
  };

  return (
    <PreviewWrapper>
      <Wrapper>
        <UploadLabel htmlFor="logoUpload">
          <CoverImageUploadArea>
            {logoPreview ? (
              <LogoPreview src={logoPreview} alt="Logo preview" />
            ) : (
              <>
                <CameraIcon />
              </>
            )}
          </CoverImageUploadArea>
        </UploadLabel>
        <HiddenInput
          ref={fileInputRef}
          type="file"
          id="logoUpload"
          accept="image/jpeg,image/png"
          onChange={handleImageUpload}
        />
        {error && <ErrorText>{error}</ErrorText>}
        <ButtonContainer>
          <Button variant="secondary" type="button" onClick={() => fileInputRef.current?.click()}>
            <UploadIcon /> {l10n(Text.upload)}
          </Button>
          <Button
            disabled={!selectedFile || Boolean(error)}
            variant="secondary"
            type="button"
            onClick={handleDelete}
          >
            <CloseIcon /> {l10n(Text.delete)}
          </Button>
        </ButtonContainer>
        <FileTypeText>{l10n(Text.fileTypes)}</FileTypeText>
        <UploadText>{l10n(Text.imageRecommendation)}</UploadText>
      </Wrapper>
      <ButtonRow
        onCancel={handleCancel}
        onConfirm={handleApply}
        confirmVariant="secondary"
        confirmDisabled={false}
      />
    </PreviewWrapper>
  );
}
