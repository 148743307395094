import React from 'react';
import { usePopupStore } from '^/store/popup';
import * as T from '^/types';
import ProjectPopup from './ProjectPopup';
import { UseFormSetValue } from 'react-hook-form';
import { AddProjectFormDataTypes } from './addProjectFormUtils';

interface ProjectPopupsProps {
  setValue: UseFormSetValue<AddProjectFormDataTypes>;
}

const ProjectPopups = ({ setValue }: ProjectPopupsProps) => {
  const minorPopup = usePopupStore(s => s.minorPopup);

  switch (minorPopup?.type) {
    case T.ContentPageMinorPopupType.PROJECT_PAGE_POPUP:
      return <ProjectPopup setValue={setValue} />;
    default:
      return null;
  }
};

export default ProjectPopups;
