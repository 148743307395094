import React from 'react';
import styled from 'styled-components';
import Button, { ButtonVariants } from '^/components/atoms/Button';
import { useL10n } from '^/hooks';
import Text from './text';

const Container = styled.div({
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
  borderTop: '1px solid #E5E5E5',
  paddingTop: '20px',
  width: '100%',
});

interface ButtonRowProps {
  onCancel?(): void;
  onConfirm?(): void;
  confirmText?: string;
  cancelText?: string;
  cancelVariant?: ButtonVariants;
  confirmVariant?: ButtonVariants;
  confirmDisabled?: boolean;
  hideConfirmButton?: boolean;
}

export default function ButtonRow({
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  cancelVariant = 'secondary',
  confirmVariant = 'primary',
  confirmDisabled = false,
  hideConfirmButton = false,
}: ButtonRowProps) {
  const [l10n] = useL10n();

  return (
    <Container>
      <Button variant={cancelVariant} onClick={onCancel} size="large" type="button">
        {cancelText || l10n(Text.cancel)}
      </Button>
      {hideConfirmButton ? null : (
        <Button
          variant={confirmVariant}
          onClick={onConfirm}
          size="large"
          disabled={confirmDisabled}
        >
          {confirmText || l10n(Text.apply)}
        </Button>
      )}
    </Container>
  );
}
