import { addMinutes, format } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';

import * as T from '^/types';

interface FormatWithOffsetOptions {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}

/**
 * @desc Following is defined in type of date-fns
 */
type FormatWithOffset = (
  offset: number,
  date: Date,
  formatString: string,
  options?: FormatWithOffsetOptions
) => string;
export const formatWithOffset: FormatWithOffset = (offset, date, formatString, options) => {
  const environmentOffset: number = new Date(date).getTimezoneOffset();

  return format(addMinutes(date, environmentOffset - offset), formatString, options);
};

export enum DateConstant {
  HOUR = 24,
  MINUTE = 60,
  SECOND = 60,
  MILLISECOND = 1e3,
}

/**
 * @desc (UTC <-> GMT) Increase or subtract by that time to avoid changing dates during conversion. (Aligned by 00 o'clock)
 */
export const makeConsistentUTCDateViaOffset: (date: Date, timezoneOffset: number) => Date = (
  date,
  timezoneOffset
) =>
  new Date(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() -
      DateConstant.MINUTE * DateConstant.MILLISECOND * timezoneOffset
  );
/**
 * Frequently used formats
 * You can check en-US formats in this link
 * https://date-fns.org/v2.9.0/docs/format
 */
export enum Formats {
  /* Long localized date */
  PP = 'PP', // en: May 29, 1453
  PPP = 'PPP', // en: May 29th, 1453, ko: 2020년 2월 10일

  /* Long localized time */
  pp = 'pp', // en: 12:00:00 AM

  /* ISO day of week */
  iii = 'iii', // en: Mon, ko: 월
  iiii = 'iiii', // en: Monday, ko: 월요일

  YYYYMMDD = 'yyyy. MM. dd', // ko: 2020. 12. 23
  YYMMDD = 'yy. MM. dd', // 20. 04. 24
  YYYY_MM_DD = 'yyyy-MM-dd', // 2021-05-13
  YYYY_MM_DD_HH_MM = 'yyyy-MM-dd HH:mm', // 2021-05-13 14:30
}

interface CommonFormat {
  [T.Language.KO_KR](hasDay: boolean): string;
  [T.Language.EN_US](hasDay: boolean): string;
}

const commonFormat: CommonFormat = {
  [T.Language.KO_KR]: hasDay =>
    !hasDay ? Formats.YYYYMMDD : `${Formats.YYYYMMDD} (${Formats.iii})`,
  [T.Language.EN_US]: hasDay => (!hasDay ? Formats.PP : `${Formats.iii}, ${Formats.PP}`),
};

export const GetCommonFormat: (params: { lang: T.Language; hasDay: boolean }) => string = ({
  lang,
  hasDay,
}) => commonFormat[lang](hasDay);

export const ApplyOptionIfKorean: (
  lang?: T.Language,
  option?: FormatWithOffsetOptions
) => FormatWithOffsetOptions = (lang = T.Language.KO_KR, option) => ({
  ...option,
  locale: lang === T.Language.KO_KR ? dateFnsLocales.ko : dateFnsLocales.enUS,
});

export const getFormattedDate: (
  timezoneOffset: number,
  customFormat: string
) => (date: Date) => string = (timezoneOffset, customFormat) => date =>
  formatWithOffset(timezoneOffset, date, customFormat);

export function formatCreatedAt(timezoneOffset: number, createdAt: Date, lang: T.Language) {
  if (createdAt) {
    return formatWithOffset(
      timezoneOffset,
      createdAt,
      GetCommonFormat({ lang, hasDay: true }),
      ApplyOptionIfKorean(lang)
    );
  }
  return null;
}

/**
 * Gets timezone abbreviation based on offset minutes
 * @param timezoneOffset Offset in minutes
 * @returns Timezone abbreviation (e.g., UTC+9, UTC-5)
 */
export const getTimezoneAbbreviation = (timezoneOffset: number): string => {
  // Convert minutes to hours
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  const minutes = Math.abs(timezoneOffset) % 60;

  // Format sign (negative offset means UTC+, positive means UTC-)
  const sign = timezoneOffset <= 0 ? '+' : '-';

  // Format the timezone string
  return `UTC${sign}${hours}${minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : ''}`;
};

/**
 * Formats a date as "YYYY-MM-DD HH:mm (timezone)"
 * @param timezoneOffset Offset in minutes
 * @param date Date to format
 * @returns Formatted date string
 */
export const getFormattedDateAndTimezone = (
  timezoneOffset: number,
  date: Date,
  lang: T.Language
): string => {
  const formattedDate = formatWithOffset(
    timezoneOffset,
    date,
    Formats.YYYY_MM_DD_HH_MM,
    ApplyOptionIfKorean(lang)
  );
  const timezoneAbbr = getTimezoneAbbreviation(timezoneOffset);

  return `${formattedDate} (${timezoneAbbr})`;
};
