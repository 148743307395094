import React, { lazy, PropsWithChildren, ReactNode, Suspense } from 'react';
import { Navigate, RouteObject, generatePath } from 'react-router-dom';

// import LoadingScreen from '^/components/molecules/LoadingScreenOld';

import * as T from '^/types';
import minDelayPromise from '^/utilities/min-delay-promise';
import ProjectManageTab from '^/containers/organisms/ProjectManageTab';
import ContentLayout from '^/containers/pages/ContentPage/layout';
import PhotoList from '^/components/molecules/PhotoList';
import TimelapseCollectionList from '^/components/molecules/TimelapseCollectionList';
import PhotoPage from '^/components/pages/PhotoPage';
import PhotoTypeList from '^/components/molecules/PhotoTypeList';
import NonDatePhotoList from '^/components/molecules/NonDatePhotoList';
import TimelapseInspectionlist from '^/components/molecules/TimelapseInspectionlist';
import PhotoAlbumCollectionList from '^/components/molecules/PhotoAlbumCollectionList';
import PhotoAlbumTypeList from '^/components/molecules/PhotoTypeList/PhotoAlbumTypeList';
import LoadingScreen from '^/components/molecules/LoadingScreenNew';
import MyPageAccount from '^/components/organisms/MyPage/account';
import MyPageSecurity from '^/components/organisms/MyPage/security';
import MyPageSettings from '^/components/organisms/MyPage/settings';
import AddProjectForms from '^/components/organisms/AddProjectForms';
import MyPageBilling from '^/components/organisms/MyPage/billing';

const minDelayForPage = 0;
const fallbackNode: NonNullable<ReactNode> = <LoadingScreen />;

function Waitable<P>(Comp: React.ComponentType<any>): React.FC<P> {
  return props => (
    <Suspense fallback={fallbackNode}>
      <Comp {...props} />
    </Suspense>
  );
}

const LoginPage = Waitable(
  lazy(async () => minDelayPromise(import('^/components/pages/LoginPage'), minDelayForPage))
);
const AuthPage = Waitable(
  lazy(async () => minDelayPromise(import('^/components/pages/LoginPage'), minDelayForPage))
);
const PrivatePolicyPage = Waitable(
  lazy(async () => minDelayPromise(import('^/components/pages/PrivatePolicyPage'), minDelayForPage))
);
const TermsPage = Waitable(
  lazy(async () => minDelayPromise(import('^/components/pages/TermsPage'), minDelayForPage))
);
export const ContentPage = Waitable<PropsWithChildren>(
  lazy(async () => minDelayPromise(import('^/components/pages/ContentPage'), minDelayForPage))
);
// export const PhotoPage = Waitable<PropsWithChildren>(
//   lazy(async () => minDelayPromise(import('^/components/pages/PhotoPage'), minDelayForPage))
// );
const DroneStationContentPage = Waitable(
  lazy(async () =>
    minDelayPromise(
      import('^/components/pages/ContentPage/DroneStationContentPage'),
      minDelayForPage
    )
  )
);
const PasswordPage = Waitable(
  lazy(async () => minDelayPromise(import('^/containers/pages/PasswordPage'), minDelayForPage))
);
const PasswordResetPage = Waitable(
  lazy(async () => minDelayPromise(import('^/containers/pages/PasswordResetPage'), minDelayForPage))
);
const ProjectPage = Waitable(
  lazy(async () => minDelayPromise(import('^/components/pages/ProjectPage'), minDelayForPage))
);
const DashboardTab = Waitable(
  lazy(async () => minDelayPromise(import('^/containers/organisms/DashboardTab'), minDelayForPage))
);

const MyPageLayout = Waitable(
  lazy(async () => minDelayPromise(import('^/components/organisms/MyPageLayout'), minDelayForPage))
);

const ProjectListTabContainer = Waitable(
  lazy(async () =>
    minDelayPromise(import('^/containers/organisms/ProjectListTab'), minDelayForPage)
  )
);
const ProjectDroneStationsTabContainer = Waitable(
  lazy(async () =>
    minDelayPromise(import('^/containers/organisms/ProjectDroneStationsTab'), minDelayForPage)
  )
);

const SharePage = Waitable<any>(
  lazy(async () => minDelayPromise(import('^/containers/pages/SharePage'), minDelayForPage))
);
const SignUpPage = Waitable(
  lazy(async () => minDelayPromise(import('^/containers/pages/SignUpPage'), minDelayForPage))
);

const routes = {
  login: {
    main: '/login',
  },
  auth: {
    main: '/auth',
  },
  password: {
    main: '/password',
    reset: '/reset_password/:token',
    createReset: (token: string) => generatePath('/reset_password/:token', { token }),
  },
  signup: {
    main: '/signup',
  },
  project: {
    main: '/project',
    projectList: '/project/list',
    mypage: '/project/mypage',
    addProject: '/project/list/add-project',
    security: '/project/mypage/security',
    billing: '/project/mypage/billing',
    settings: '/project/mypage/settings',
    dashboard: '/project/dashboard',
    manage: '/project/:id/manage',
    createManage: (id: string) => generatePath('/project/:id/manage', { id }),
  },
  share: {
    main: '/share/:token',
    createMain: (token: string) => generatePath('/share/:token', { token }),
  },
  droneStation: {
    main: '/project/drone_station',
  },
  droneStationContent: {
    main: '/drone_station/:id/content',
    createMain: (id: string) => generatePath('/drone_station/:id/content', { id }),
  },
  content: {
    layout: '/project/:id/content',
    main: ':tab?/:screenId?/:contentId?',
    photo: 'photo/:screenId?/:contentId?',
    timelapse: 'photo/:screenId?/timelapse',
    createMain: (
      id: string,
      tab: string | null,
      screenId: string | null,
      contentType: string | null,
      contentId: string | null
    ) =>
      generatePath(`/project/:id/content/:tab?/:screenId?/:contentType?/:contentId?`, {
        id,
        tab,
        screenId,
        contentType,
        contentId,
      }),
  },

  externalLink: {
    terms: 'https://angelswing.io/en/terms',
    privatePolicy: 'https://angelswing.io/en/privacy',
    homepage: 'https://angelswing.io',
    homepageLang: {
      [T.Language.KO_KR]: 'https://angelswing.io/ko-kr',
      [T.Language.EN_US]: 'https://angelswing.io/en',
    },
    support: {
      [T.Language.KO_KR]: 'https://angelswing.notion.site/0eed365c69fd49d8aeb6377a0518ed77',
      [T.Language.EN_US]: 'https://angelswing.notion.site/442da4b1167c4d8dbf2a8916d5177317',
    },
    essSupport: {
      [T.Language.KO_KR]: 'https://angelswing.notion.site/ESS-c3d5a22aa82e4a279abd5ad4cd8b874d',
      [T.Language.EN_US]: 'https://angelswing.notion.site/ESS-c3d5a22aa82e4a279abd5ad4cd8b874d',
    },
    releaseNotes: {
      [T.Language.KO_KR]: 'https://angelswing.notion.site/b5ab5073ce1946c68b659418bd7f04c2',
      [T.Language.EN_US]:
        'https://angelswing.notion.site/Angelswing-Product-Updates-aa3eddb3f66648eab2dd0c6aff8c857f',
    },
    chromeDownloadURL: 'https://www.google.com/chrome/',
    chromeMoreInformationURL:
      // eslint-disable-next-line max-len
      'https://support.angelswing.io/hc/ko/articles/360039595374--%EC%A4%91%EC%9A%94-%EA%B3%B5%EC%A7%80-%EC%97%94%EC%A0%A4%EC%8A%A4%EC%9C%99-%ED%94%8C%EB%9E%AB%ED%8F%BC-1-9-3-%EB%B2%84%EC%A0%84%EB%B6%80%ED%84%B0-%EC%9D%B8%ED%84%B0%EB%84%B7-%EC%9D%B5%EC%8A%A4%ED%94%8C%EB%A1%9C%EB%9F%AC-%EC%A7%80%EC%9B%90%EC%9D%84-%EC%A4%91%EB%8B%A8%ED%95%A9%EB%8B%88%EB%8B%A4-',
    howToMakeDesignURL:
      // eslint-disable-next-line max-len
      'https://support.angelswing.io/hc/ko/articles/360039595374--%EC%A4%91%EC%9A%94-%EA%B3%B5%EC%A7%80-%EC%97%94%EC%A0%A4%EC%8A%A4%EC%9C%99-%ED%94%8C%EB%9E%AB%ED%8F%BC-1-9-3-%EB%B2%84%EC%A0%84%EB%B6%80%ED%84%B0-%EC%9D%B8%ED%84%B0%EB%84%B7-%EC%9D%B5%EC%8A%A4%ED%94%8C%EB%A1%9C%EB%9F%AC-%EC%A7%80%EC%9B%90%EC%9D%84-%EC%A4%91%EB%8B%A8%ED%95%A9%EB%8B%88%EB%8B%A4-',
    cadError: 'https://support.angelswing.io/hc/ko/articles/900006637926',
    volumeCalculation: {
      [T.Language.KO_KR]: 'https://angelswing.notion.site/7329387f379448c6b62a5b17fca1e855',
      [T.Language.EN_US]: 'https://angelswing.notion.site/4e0746f711c345a69919a026042a97c2',
    },
  },
};

// async function loader({
//   // request,
//   dispatch,
//   projectId,
// }: {
//   request: Request;
//   dispatch: Dispatch;
//   projectId?: number;
// }) {
//   if (projectId) {
//     batch(() => {
//       contentPageEnterActions(projectId).forEach(dispatch);
//     });
//   }

//   return { projectId };
// }

export const authorizedRoutes = (pathBeforeAuth?: string): RouteObject[] => [
  {
    path: routes.project.main,
    element: <ProjectPage />,
    children: [
      {
        index: true,
        element: <Navigate to={routes.project.dashboard} replace={true} />,
      },
      {
        path: routes.project.dashboard,
        element: <DashboardTab />,
      },
      {
        path: routes.project.projectList,
        element: <ProjectListTabContainer />,
      },
      {
        path: routes.project.addProject,
        element: <AddProjectForms />,
      },
      {
        path: routes.project.mypage,
        element: <MyPageLayout />,
        children: [
          { index: true, element: <MyPageAccount /> },
          {
            path: routes.project.security,
            element: <MyPageSecurity />,
          },
          {
            path: routes.project.billing,
            element: <MyPageBilling />,
          },
          {
            path: routes.project.settings,
            element: <MyPageSettings />,
          },
        ],
      },
      {
        path: routes.droneStation.main,
        element: <ProjectDroneStationsTabContainer />,
      },
      {
        path: routes.project.manage,
        element: <ProjectManageTab />,
      },
    ],
  },
  {
    path: routes.droneStationContent.main,
    element: <DroneStationContentPage />,
  },
  {
    path: routes.content.layout,
    element: <ContentLayout />,
    loader: ({ params }) => ({ projectId: Number(params.id) }),
    children: [
      {
        path: routes.content.main,
        element: <ContentPage />,
        children: [
          {
            path: routes.content.photo,
            element: <PhotoPage />,
            children: [
              {
                index: true,
                element: <PhotoList />,
              },
              {
                path: 'location',
                element: <TimelapseCollectionList />,
              },
              {
                path: 'timelapse',
                element: <TimelapseInspectionlist />,
              },
              {
                path: 'album',
                element: <PhotoAlbumCollectionList />,
              },
              {
                path: 'album/:albumType/:albumId',
                element: <PhotoAlbumTypeList />,
              },
              {
                path: 'no_date',
                element: <NonDatePhotoList />,
              },
              {
                path: ':contentType/:takenAt',
                element: <PhotoTypeList />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routes.share.main,
    element: <SharePage sharedContentsStatus={T.APIStatus.IDLE} />,
  },
  {
    path: '*',
    element: <Navigate to={pathBeforeAuth ? pathBeforeAuth : routes.project.main} />,
  },
];

export const unauthorizedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: routes.login.main,
    element: <LoginPage />,
  },
  {
    path: routes.auth.main,
    element: <AuthPage />,
  },
  {
    path: routes.password.main,
    element: <PasswordPage />,
  },
  {
    path: routes.password.reset,
    element: <PasswordResetPage />,
  },
  {
    path: routes.signup.main,
    element: <SignUpPage />,
  },
  {
    path: routes.share.main,
    element: <SharePage sharedContentsStatus={T.APIStatus.IDLE} />,
  },
  {
    path: routes.externalLink.privatePolicy,
    element: <PrivatePolicyPage />,
  },
  {
    path: routes.externalLink.terms,
    element: <TermsPage />,
  },
  {
    path: '*',
    element: <Navigate to={routes.login.main} />,
  },
];

export default routes;
