import theme from '^/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

interface TabItem {
  id: number;
  label: string;
  hasError?: boolean;
}

interface StepTabsProps {
  tabs: TabItem[];
  errorTabs: number[];
  currentTab?: number;
  completedTabs: number[];
  onTabClick(id: number): void;
}

const TabsContainer = styled.div({
  display: 'flex',
  gap: '8px',
});

const ErrorDot = styled.div({
  position: 'absolute',
  top: '-6px',
  right: '-6px',
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  background: theme.colorTokens.errorRed,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '12px',
  fontWeight: 'bold',
});

const TabItem = styled.button<{
  active: boolean;
  disabled?: boolean;
  isCompleted: boolean;
  hasError?: boolean;
}>(({ active, disabled, isCompleted, hasError }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  paddingTop: '16px',
  paddingBottom: '16px',
  textDecoration: 'none',
  fontWeight: '700',
  color: active
    ? theme.colorTokens.accentBlue
    : hasError
    ? theme.colorTokens.errorRed
    : isCompleted
    ? theme.colorTokens.accentBlue
    : theme.colorTokens.textCoolLight,
  borderBottom: `2px solid ${active ? theme.colorTokens.accentBlue : 'transparent'}`,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',

  '&:hover': {
    color: active
      ? theme.colorTokens.accentBlue
      : hasError
      ? theme.colorTokens.errorRed
      : theme.colorTokens.accentBlue,
    span: {
      borderColor: active
        ? theme.colorTokens.accentBlue
        : hasError
        ? theme.colorTokens.errorRed
        : theme.colorTokens.accentBlue,
    },
  },
  span: {
    borderColor: active
      ? theme.colorTokens.accentBlue
      : hasError
      ? theme.colorTokens.errorRed
      : isCompleted
      ? theme.colorTokens.accentBlue
      : theme.colorTokens.textCoolLight,
    background:
      active || hasError
        ? 'transparent'
        : isCompleted
        ? theme.colorTokens.accentBlue
        : 'transparent',
    color: active
      ? theme.colorTokens.accentBlue
      : hasError
      ? theme.colorTokens.errorRed
      : isCompleted
      ? 'white'
      : '',
  },
}));

const StepNumberWrapper = styled.div({
  position: 'relative',
  marginRight: '8px',
  display: 'flex',
  alignItems: 'center',
});

const StepNumber = styled.span(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  border: `2px solid ${theme.colorTokens.textCoolLight}`,
  fontSize: '14px',
}));

const DividerBar = styled.div({
  width: '20px',
  height: '1px',
  background: theme.colorTokens.textCoolLight,
  marginRight: '8px',
});

const TabItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const StepTabs: FC<StepTabsProps> = ({
  tabs,
  errorTabs,
  currentTab,
  completedTabs,
  onTabClick,
}) => (
  <TabsContainer>
    {tabs.map((tab, index) => {
      const isFirstItem = index === 0;
      const isActive = currentTab === tab.id;
      const hasError = errorTabs.includes(tab.id);
      const isCompleted = completedTabs.includes(tab.id);
      return (
        <TabItemWrapper key={tab.id}>
          {!isFirstItem && <DividerBar />}
          <TabItem
            disabled={false}
            active={isActive}
            hasError={hasError}
            isCompleted={isCompleted}
            onClick={() => onTabClick(tab.id)}
          >
            <StepNumberWrapper>
              <StepNumber>{tab.id}</StepNumber>
              {hasError && !isActive && <ErrorDot>!</ErrorDot>}
            </StepNumberWrapper>
            {tab.label}
          </TabItem>
        </TabItemWrapper>
      );
    })}
  </TabsContainer>
);
