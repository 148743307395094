import React, { useEffect } from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { AddProjectFormDataTypes } from './addProjectFormUtils';
import Dropdown, { Option } from '^/components/atoms/Dropdown/1';
import Button from '^/components/atoms/Button';
import theme from '^/theme';
import CloseSVG from '^/assets/icons/close-gray.svg';
import ErrorInfoIcon from '^/assets/icons/error-info.svg';
import InfoIcon from '^/assets/icons/photo/info-circle.svg';
import { usePermissionDropdownOptions } from '^/components/molecules/ProjectPermissionItem';
import Tippy from '@tippyjs/react';
import * as T from '^/types';
import { usePopupStore } from '^/store/popup';
import { HorizontalDivider } from '^/components/atoms/ContentsListItem';
import LoadingIcon from '^/components/atoms/LoadingIcon';
import { useL10n } from '^/hooks';
import Text from './text';
import PlusSvg from '^/assets/icons/plus.svg';

const Root = styled.div({
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled.div({
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
});

const Title = styled.h2({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.colorTokens.textCoolDark,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const ViewDetails = styled.span({
  color: theme.colorTokens.textCoolLight,
  fontSize: '14px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const InputRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const RoleDropdown = styled(Dropdown as AnyStyledComponent)({
  width: '200px',
  minWidth: '200px',
});

const EmailInput = styled.input<{ hasError?: boolean }>(({ hasError }) => ({
  flex: 1,
  padding: '12px',
  border: `1px solid ${hasError ? theme.colorTokens.errorRed : theme.colorTokens.inputBorderColor}`,
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: hasError ? '#FFF5F5' : 'white',
  '&::placeholder': {
    color: theme.colorTokens.textCoolLight,
  },
}));

const CollaboratorList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const CollaboratorRow = styled.div<{ hasError?: boolean; isDuplicate?: boolean }>(
  ({ hasError, isDuplicate }) => ({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: '12px',
    padding: '12px',
    backgroundColor: hasError
      ? '#FFF5F5'
      : isDuplicate
      ? '#FFF9E7'
      : theme.colorTokens.bgLightCoolGray,
    borderRadius: '4px',
  })
);

const CloseIcon = styled(CloseSVG)({
  width: '16px',
  height: '16px',
  cursor: 'pointer',
  marginLeft: 'auto',
  '& path': {
    fill: theme.colorTokens.textCool,
  },
});

const HelperText = styled.p({
  color: theme.colorTokens.textCoolLight,
  fontSize: '14px',
  margin: '4px 0',
});

const FooterText = styled.p({
  color: theme.colorTokens.textCoolLight,
  fontSize: '14px',
  marginTop: '16px',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const WhitePlusIcon = styled(PlusSvg)({
  width: '16px',
  '& path': {
    fill: '#fff',
  },
});

interface Collaborator {
  role: string;
  email: string;
  isValid: boolean;
}

interface ProjectShareFormProps {
  setValue: UseFormSetValue<AddProjectFormDataTypes>;
  isValid: boolean;
  watch: UseFormWatch<AddProjectFormDataTypes>;
  isLoading: boolean;
}

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email?.trim());
};

const ProjectShareForm: React.FC<ProjectShareFormProps> = ({
  setValue,
  isValid,
  watch,
  isLoading,
}) => {
  const [l10n] = useL10n();
  const formCollaborators = watch('collaborators') ?? [];
  const [selectedRole, setSelectedRole] = React.useState('admin');
  const [emailInput, setEmailInput] = React.useState('');
  const [highlightedEmails, setHighlightedEmails] = React.useState<string[]>([]);
  const [collaborators, setCollaborators] = React.useState<Collaborator[]>(
    formCollaborators.map((collaborator: { role: string; email: string; isValid: boolean }) => ({
      role: collaborator.role,
      email: collaborator.email,
      isValid: validateEmail(collaborator.email),
    }))
  );

  const setPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupOptions = usePopupStore(s => s.setPopupOptions);

  const roleOptions = usePermissionDropdownOptions();

  const isDuplicateEmail = (email: string, currentIndex?: number): boolean =>
    collaborators.some(
      (c, index) =>
        c.email?.toLowerCase() === email?.toLowerCase() &&
        (currentIndex === undefined || index !== currentIndex)
    ) || highlightedEmails.includes(email?.toLowerCase());

  useEffect(() => {
    if (highlightedEmails.length > 0) {
      const timer = setTimeout(() => {
        setHighlightedEmails([]);
      }, 3000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [highlightedEmails]);

  const handleAddCollaborators = () => {
    if (!emailInput.trim()) {
      return;
    }

    const emails = emailInput.split(',').map(email => email.trim());
    const uniqueEmails = new Set<string>();
    const duplicateEmails = new Set<string>();

    emails.forEach(email => {
      const lowerEmail = email.toLowerCase();
      if (
        uniqueEmails.has(lowerEmail) ||
        collaborators.some(c => c.email.toLowerCase() === lowerEmail)
      ) {
        duplicateEmails.add(lowerEmail);
      } else {
        uniqueEmails.add(lowerEmail);
      }
    });

    if (duplicateEmails.size > 0) {
      setHighlightedEmails([...duplicateEmails]);
    }

    const newCollaborators = [...uniqueEmails].map(email => ({
      role: selectedRole,
      email,
      isValid: validateEmail(email),
    }));

    if (newCollaborators.length > 0) {
      const updatedCollaborators = [...collaborators, ...newCollaborators];
      setCollaborators(updatedCollaborators);
      setValue('collaborators', updatedCollaborators);
    }

    setEmailInput('');
  };

  const handleRemoveCollaborator = (index: number) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
    setValue('collaborators', newCollaborators);
  };

  const handleRoleChange = (option: Option) => {
    setSelectedRole(option.value as string);
  };

  const handleCollaboratorRoleChange = (index: number, option: Option) => {
    const newCollaborators = [...collaborators];
    newCollaborators[index].role = option.value as string;
    setCollaborators(newCollaborators);
  };
  const onClickHandler = (type: T.ProjectPagePopupType) => {
    setPopup(T.ContentPageMinorPopupType.PROJECT_PAGE_POPUP);
    setPopupOptions({ projectPagePopupType: type });
  };

  return (
    <Root>
      <Header>
        <Title>{l10n(Text.addCollaborators)}</Title>
        <ViewDetails
          onClick={() => onClickHandler(T.ProjectPagePopupType.PROJECT_ROLE_INFORMATION)}
        >
          <InfoIcon />
          {l10n(Text.viewDetails)}
        </ViewDetails>
      </Header>
      <InputRow>
        <RoleDropdown
          value={selectedRole}
          options={roleOptions}
          onClick={handleRoleChange}
          placeHolder="Select Role"
          zIndex={0}
        />
        <EmailInput
          value={emailInput}
          onChange={e => setEmailInput(e.target.value)}
          placeholder={l10n(Text.emailPlaceholder)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleAddCollaborators();
            }
          }}
        />
        <Button
          variant="primary"
          size="large"
          onClick={handleAddCollaborators}
          disabled={!emailInput.trim()}
        >
          <WhitePlusIcon />
          {l10n(Text.add)}
        </Button>
      </InputRow>
      <HelperText>{l10n(Text.multipleEmailsHelper)}</HelperText>
      {collaborators?.length === 0 && (
        <CollaboratorRow hasError={false} isDuplicate={false}>
          <ViewDetails>{l10n(Text.noCollaborators)}</ViewDetails>
        </CollaboratorRow>
      )}
      <CollaboratorList>
        {collaborators.map((collaborator, index) => (
          <Wrapper key={index}>
            <CollaboratorRow
              hasError={!collaborator.isValid}
              isDuplicate={isDuplicateEmail(collaborator.email, index)}
            >
              <RoleDropdown
                value={collaborator.role}
                options={roleOptions}
                onClick={(option: Option) => handleCollaboratorRoleChange(index, option)}
                placeHolder="Select Role"
                mainButtonStyle={{ backgroundColor: 'transparent', border: 0, boxShadow: 'none' }}
                zIndex={0}
              />
              <span>{collaborator.email}</span>
              {!collaborator.isValid && (
                <Tippy content={l10n(Text.invalidEmailError)}>
                  <ErrorInfoIcon />
                </Tippy>
              )}
              {isDuplicateEmail(collaborator.email, index) && (
                <Tippy content={l10n(Text.duplicateEmailError)}>
                  <ErrorInfoIcon />
                </Tippy>
              )}
            </CollaboratorRow>
            <CloseIcon onClick={() => handleRemoveCollaborator(index)} />
          </Wrapper>
        ))}
      </CollaboratorList>
      <FooterText>{l10n(Text.infoText)}</FooterText>
      <HorizontalDivider />
      <ButtonWrapper>
        <Button
          variant="secondary"
          size="large"
          onClick={() => {
            setValue('isCollaboratorsSkipped', true);
            setValue('collaborators', []);
          }}
        >
          {l10n(Text.skipForNow)}
        </Button>
        <Button
          variant="primary"
          size="large"
          disabled={!isValid || isLoading}
          onClick={() => {
            setValue('isCollaboratorsSkipped', false);
            setValue('collaborators', collaborators);
          }}
        >
          {isLoading ? <LoadingIcon /> : l10n(Text.createProject)}
        </Button>
      </ButtonWrapper>
    </Root>
  );
};

export default ProjectShareForm;
