import { z } from 'zod';
import * as T from '^/types';
import { l10n } from '^/utilities/l10n';
import Text, { permissionNames, roleNames } from './text';

export const addProjectValidationSchema = (currentTab: number, language: T.Language) =>
  z.object({
    title: z
      .string()
      .trim()
      .min(4, l10n(Text.minLengthError, language))
      .nonempty(l10n(Text.required, language)),
    description: z.string().trim().max(300, l10n(Text.maxLengthError, language)).optional(),
    unit: z.string().nonempty('Unit type is required'),
    coordinateSystem: z.string().nonempty('Coordinate system is required'),
  });

export const rolesData = [
  {
    name: roleNames.admin,
    permissions: [
      { name: permissionNames.view, isEnabled: true },
      { name: permissionNames.edit, isEnabled: true },
      { name: permissionNames.delete, isEnabled: true },
      { name: permissionNames.upload, isEnabled: true },
      { name: permissionNames.share, isEnabled: true },
    ],
  },
  {
    name: roleNames.pilot,
    permissions: [
      { name: permissionNames.view, isEnabled: true },
      { name: permissionNames.edit, isEnabled: true },
      { name: permissionNames.delete, isEnabled: true },
      { name: permissionNames.upload, isEnabled: true },
    ],
  },
  {
    name: roleNames.member,
    permissions: [
      { name: permissionNames.view, isEnabled: true },
      { name: permissionNames.edit, isEnabled: true },
    ],
  },
  {
    name: roleNames.viewer,
    permissions: [{ name: permissionNames.view, isEnabled: true }],
  },
];

export interface ProjectInformationFormDataTypes {
  title: string;
  description: string;
  thumbnail: File | null;
  logo: File | null;
  imageGenerationType: 'auto' | 'manual';
}

export interface ProjectSettingFormDataTypes {
  unit: string;
  coordinateSystem: string;
}

export interface ProjectShareFormDataTypes {
  isCollaboratorsSkipped?: boolean;
  collaborators: Array<{
    role: string;
    email: string;
    isValid: boolean;
  }>;
}

export interface AddProjectFormDataTypes
  extends ProjectInformationFormDataTypes,
    ProjectSettingFormDataTypes,
    ProjectShareFormDataTypes {}
