import React, { useRef, useState, useEffect, MouseEvent, useMemo, MouseEventHandler } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';
import { ContentText } from '../SourcePhotoSelector';

import SourceIconSvg from '^/assets/icons/timelapse/source-icon.svg';
import InspectionIconSvg from '^/assets/icons/timelapse/inspection-icon.svg';
import FilterSVG from '^/assets/icons/filter.svg';
import CheckSvg from '^/assets/icons/contents-list/check.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import EmptyCollectionSvg from '^/assets/icons/timelapse/empty-collection.svg';
import BevIconSvg from '^/assets/icons/timelapse/bev-icon.svg';

import EllipsisSvg from '^/assets/icons/presentation/ellipsis.svg';

import { TimelapseCollection, useTimelapseStore } from '^/store/zustand/timelapse/timelapseStore';
import { MenuWrapper, PhotoListItem } from '../PhotoList/style';
import {
  FilterItem,
  FilterName,
  FilterPortalWrapper,
  FilterRoot,
  FilterTitle,
  FilterWrapper,
  IconWrapper,
} from './Styles';

import * as T from '^/types';

import Text from './text';
import { useClickOutside, useIsInView, useL10n } from '^/hooks';
import dsPalette from '^/constants/ds-palette';
import theme from '^/theme';
import PhotoAlbumTitleInput from '../PhotoAlbum/PhotoAlbumTitleInput';
import useTimelapseContents from '^/hooks/timelapse/useTimelapseContents';
import { useFlag } from '@unleash/proxy-client-react';

interface TimelapseCollectionItemProps {
  timelapseCollection: TimelapseCollection;
  isDisabled?: boolean;
  handleClose?(): void;
}

const TimelapseCollectionContainer = styled(PhotoListItem)({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '6px',
    zIndex: 1,
  },

  zIndex: 0,
  overflow: 'hidden',

  [`&:hover ${MenuWrapper}`]: {
    display: 'flex',
  },

  '&:hover': {
    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    '& div': {
      opacity: 1,
    },
  },
});

const TimelapseCollectionWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',

  zIndex: 1,
});

const TimelapseContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
});

const DropdownContent = styled.div({
  position: 'absolute',
  top: '24px',
  right: '0',
  backgroundColor: theme.colors.white[100],
  borderRadius: '4px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  padding: '4px',
  width: '90px',
  zIndex: 2,
});

const DropdownItem = styled.button<{ isDestructive?: boolean }>(props => ({
  width: '100%',
  padding: '3px 8px',
  fontSize: '12px',
  textAlign: 'left',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  color: props.isDestructive ? '#EF4444' : theme.colors.black[100],

  ':hover': {
    backgroundColor: theme.colors.black[10],
  },
}));

const InspectionTitle = styled.div({
  color: 'white',
});

const TimelapseCollectionItem: React.FC<TimelapseCollectionItemProps> = ({
  timelapseCollection,
}) => {
  const isPhotoAlbumNewFeaturesEnabled = useFlag(T.FEATURE_FLAGS.PHOTO_ALBUM_NEW_FEATURES);
  const { id: projectId } = useParams();
  const [l10n] = useL10n();
  const [ref, isInView] = useIsInView<HTMLDivElement>();

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { setSelectedCollectionId } = useTimelapseStore(s => ({
    setSelectedCollectionId: s.setSelectedCollectionId,
  }));

  const { renameTimelapseCollection } = useTimelapseContents(Number(projectId));

  const navigate = useNavigate();

  useClickOutside({
    ref: dropdownRef,
    callback: () => setIsDropdownOpen(false),
  });

  const title = timelapseCollection?.name
    ? timelapseCollection.name
    : `Location ${timelapseCollection.id}`;
  const photoCover = timelapseCollection.thumbnailUrl ?? '';
  const photoIcon =
    timelapseCollection.type === T.TimelapseCollectionTypes.SOURCE ? (
      <SourceIconSvg />
    ) : timelapseCollection.type === T.TimelapseCollectionTypes.INSPECTION ? (
      <InspectionIconSvg />
    ) : timelapseCollection.type === T.TimelapseCollectionTypes.BIRD_EYE_VIEW ? (
      <BevIconSvg />
    ) : null;

  const handleSelected = () => {
    navigate(T.PhotoPathType.TIMELAPSE);
    setSelectedCollectionId(timelapseCollection.id);
  };

  const handleDropdownMenuClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRenameOptionClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setEditedTitle(title);
    setIsEditing(true);
    setIsDropdownOpen(false);
  };

  const handleCompleteRename = async () => {
    await renameTimelapseCollection(timelapseCollection.id, editedTitle);
  };

  return (
    <TimelapseCollectionContainer
      url={isInView ? photoCover : ''}
      onClick={handleSelected}
      id={`photo_${photoCover}`}
      key={photoCover}
      isSelected={false}
      isVisible={true}
      ref={ref}
    >
      {isPhotoAlbumNewFeaturesEnabled && (
        <MenuWrapper
          ref={dropdownRef}
          isDropdownOpen={isDropdownOpen}
          onClick={handleDropdownMenuClick}
          data-testid={`album-menu-${timelapseCollection.id}`}
        >
          <EllipsisSvg />

          {isDropdownOpen && (
            <DropdownContent>
              <DropdownItem onClick={handleRenameOptionClick}>{l10n(Text.rename)}</DropdownItem>
            </DropdownContent>
          )}
        </MenuWrapper>
      )}
      <TimelapseCollectionWrapper>
        <TimelapseContent>
          {photoIcon}
          {isEditing ? (
            <PhotoAlbumTitleInput
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              editedTitle={editedTitle}
              setEditedTitle={setEditedTitle}
              onSave={handleCompleteRename}
            />
          ) : (
            <InspectionTitle>{title}</InspectionTitle>
          )}
          <ContentText>{timelapseCollection.photoCount} Photos</ContentText>
        </TimelapseContent>
      </TimelapseCollectionWrapper>
    </TimelapseCollectionContainer>
  );
};

const TimelapseCollectionFilter: React.FC = () => {
  const filterPopDiv = useRef<HTMLDivElement>(document.createElement('div'));
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterPopRef = useRef<HTMLDivElement>(null);
  const [l10n] = useL10n();

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleFilterIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsFilterOpen(_isFilterOpen => !_isFilterOpen);
    const { x, y, height } = event.currentTarget.getBoundingClientRect();
    setPosition({ x, y: y + height + 10 });
  };

  const { collectionFilter, setCollectionFilter } = useTimelapseStore(s => ({
    collectionFilter: s.collectionFilter,
    setCollectionFilter: s.setCollectionFilter,
  }));

  useClickOutside({
    ref: filterPopRef,
    callback() {
      if (filterPopRef.current) {
        setIsFilterOpen(false);
      }
    },
  });

  const collectionTypes = useMemo(() => Object.values(T.TimelapseCollectionTypes), []);

  const handleFilterItemClick = (collectionType?: T.TimelapseCollectionTypes) => () => {
    if (collectionType) {
      setCollectionFilter(collectionType);
    }
  };

  const filterPopup = isFilterOpen ? (
    <React.Fragment>
      {ReactDOM.createPortal(
        <FilterPortalWrapper>
          <FilterRoot ref={filterPopRef} style={{ left: position.x, top: position.y }}>
            {collectionTypes.map(name => (
              <FilterItem key={name} onClick={handleFilterItemClick(name)}>
                <IconWrapper style={{ marginRight: 13 }}>
                  {collectionFilter === name ? <CheckSvg /> : <UncheckSvg />}
                </IconWrapper>
                <FilterName style={{ marginLeft: 13 }}>
                  {name ? l10n(Text.filterContentTexts[name]) : l10n(Text.filterText)}
                </FilterName>
              </FilterItem>
            ))}
          </FilterRoot>
        </FilterPortalWrapper>,
        filterPopDiv.current
      )}
    </React.Fragment>
  ) : null;

  useEffect(() => {
    document.body.appendChild(filterPopDiv.current);
  }, []);

  useEffect(
    () => () => {
      document.body.removeChild(filterPopDiv.current);
    },
    [filterPopDiv]
  );

  return (
    <FilterWrapper onClick={handleFilterIconClick}>
      <IconWrapper isActive={isFilterOpen}>
        <FilterSVG />
      </IconWrapper>
      <FilterTitle>{l10n(Text.filterContentTexts[collectionFilter])}</FilterTitle>
      {filterPopup}
    </FilterWrapper>
  );
};

const EmptyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  height: '100%',
  width: '100%',
  margin: '50px auto',
});

const EmptyText = styled.div({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '19px',
  color: dsPalette.typeGrey.toString(),
});

const EmptyTimelapseCollectionItem: React.FC = () => {
  const [l10n] = useL10n();
  return (
    <EmptyWrapper>
      <EmptyCollectionSvg />
      <EmptyText>{l10n(Text.emptyText)}</EmptyText>
    </EmptyWrapper>
  );
};

export { TimelapseCollectionItem, TimelapseCollectionFilter, EmptyTimelapseCollectionItem };
