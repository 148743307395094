import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { AddProjectFormDataTypes } from './addProjectFormUtils';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import RawTitle from '^/components/atoms/TitleWithDescription';
import { useL10n } from '^/hooks';
import { unitLabel } from '^/utilities/imperial-unit';
import { projectionSystemLabel } from '^/utilities/coordinate-util';
import * as T from '^/types';
import Dropdown, { Option as DropdownOption } from '^/components/atoms/Dropdown/1';
import theme from '^/theme';
import { Button } from '^/components/atoms/Button';
import { HorizontalDivider } from '^/components/atoms/ContentsListItem';
import Text from './text';
import WhiteChvIcon from '^/assets/icons/white-chv.svg';

const Section = styled.section({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '20px',
});

const SectionTitle = styled.h2({
  fontSize: '18px',
  fontWeight: '600',
  color: '#333',
  paddingBottom: '12px',
  paddingTop: '12px',
  width: '160px',
});

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const FieldsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  flexGrow: 1,
  paddingTop: '12px',
  paddingBottom: '12px',
});

const LabelWrapper = styled.div({
  marginBottom: '30px',
});

const TitleLabel = styled(RawTitle as AnyStyledComponent)({
  marginBottom: '10px',
});
const HelperText = styled.p({
  fontSize: '14px',
  color: theme.colorTokens.textCoolLight,
  fontWeight: '400',
  marginTop: '4px',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

interface ProjectSettingFormProps {
  setValue: UseFormSetValue<AddProjectFormDataTypes>;
  errors: FieldErrors<AddProjectFormDataTypes>;
  watch: UseFormWatch<AddProjectFormDataTypes>;
  isValid: boolean;
  setCurrentTab(currentStep: number): void;
  setCompletedTabs(completedTabs: number[]): void;
  completedTabs: number[];
}

const ProjectSettingForm = ({
  setValue,
  watch,
  isValid,
  setCurrentTab,
  setCompletedTabs,
  completedTabs,
}: ProjectSettingFormProps) => {
  const [l10n] = useL10n();
  const unitOptions: DropdownOption[] = Object.keys(unitLabel).map((c: T.ValidUnitType) => ({
    text: l10n(unitLabel[c]),
    value: c,
    leftText: '',
  }));

  const coordinateOptions: DropdownOption[] = Object.keys(projectionSystemLabel).map(
    (c: T.CoordinateSystem) => ({
      text: l10n(projectionSystemLabel[c]),
      value: c,
      leftText: '',
    })
  );

  const onNextClickHandler = () => {
    setCurrentTab(3);
    setCompletedTabs([...completedTabs, 2]);
  };

  return (
    <Root>
      <Section>
        <SectionTitle>{l10n(Text.spatialSettings)}</SectionTitle>
        <FieldsWrapper>
          <LabelWrapper>
            <TitleLabel title={l10n(Text.coordinateSystem)} />
            <Dropdown
              isSearchEnable={true}
              value={watch('coordinateSystem')}
              options={coordinateOptions}
              onClick={value => setValue('coordinateSystem', value.value as string)}
              placeHolder={l10n(Text.coordinateSystemPlaceholder)}
              zIndex={0}
            />
            <HelperText>{l10n(Text.coordinateSystemHelper)}</HelperText>
          </LabelWrapper>
          <LabelWrapper>
            <TitleLabel title={l10n(Text.unitSystem)} />
            <Dropdown
              value={watch('unit')}
              placeHolder={l10n(Text.unitSystemPlaceholder)}
              options={unitOptions}
              onClick={value => setValue('unit', value.value as string)}
              zIndex={0}
            />
            <HelperText>{l10n(Text.unitSystemHelper)}</HelperText>
          </LabelWrapper>
        </FieldsWrapper>
      </Section>

      <HorizontalDivider />
      <ButtonWrapper>
        <Button
          size="large"
          type="button"
          variant="primary"
          disabled={!isValid}
          customStyles={{ gap: '4px' }}
          onClick={onNextClickHandler}
        >
          {l10n(Text.nextButton)} <WhiteChvIcon />
        </Button>
      </ButtonWrapper>
    </Root>
  );
};

export default ProjectSettingForm;
