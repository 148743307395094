import { useMutation, useQueryClient } from '@tanstack/react-query';
import { http, METHODS } from '../http';
import { objectToFormData } from '^/utilities/objectToFormData';
import { AxiosRequestConfig } from 'axios';

interface MutationParameters {
  data: any;
  isMultipart: boolean;
  dynamicEndpoint?: string;
  axiosOptions: AxiosRequestConfig;
  callbackAttributes?: any;
}

interface UseReactQueryMutationParameters {
  method?: METHODS;
  enabled?: boolean;
  endpoint: string | '';
  onErrorCallback?: any;
  onSuccessCallback?: any;
  cacheUpdateCallback?: any;
  queryKey: string | string[];
}

const useReactQueryMutation = ({
  endpoint,
  queryKey,
  onErrorCallback,
  onSuccessCallback,
  cacheUpdateCallback,
  method = METHODS.POST,
}: UseReactQueryMutationParameters) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({
      data,
      axiosOptions = {},
      isMultipart = false,
      dynamicEndpoint = endpoint,
      callbackAttributes = null,
    }: MutationParameters) => {
      const formData = isMultipart ? objectToFormData(data) : data;
      const methodFunc = {
        get: http.get,
        put: http.put,
        post: http.post,
        patch: http.patch,
        delete: http.delete,
      }[method];

      if (!methodFunc) {
        return;
      }

      const response = await methodFunc(dynamicEndpoint, formData, axiosOptions);
      const qK = Array.isArray(queryKey) ? queryKey : [queryKey];

      await queryClient.invalidateQueries(qK);

      try {
        if (onSuccessCallback) {
          onSuccessCallback(response, callbackAttributes);
        }
        if (cacheUpdateCallback) {
          cacheUpdateCallback(response);
        }
      } catch {
        throw new Error('Please provide a valid callback function');
      }
      return response.data;
    },
    {
      onError: err => {
        try {
          if (onErrorCallback) {
            onErrorCallback(err);
          }
        } catch {
          throw new Error('Please provide a valid onErrorCallback function');
        }
        return err;
      },
    }
  );

  return mutation;
};

export default useReactQueryMutation;
