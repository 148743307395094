import { makeV2APIURL } from '^/store/duck/API';
import { APIPermission, APIProject } from '^/types';
import { useReactQueryMutation } from '^/utilities/api';
import { ProjectShareFormDataTypes } from './addProjectFormUtils';
import { useNavigate } from 'react-router-dom';
import routes from '^/constants/routes';

interface ProjectApiResponse {
  data: {
    data: APIProject;
  };
}

interface PermissionApiResponse {
  data: {
    data: APIPermission;
  };
  status: number;
}

export const useAddProject = () => {
  const navigate = useNavigate();

  const navigateToProjectList = () => {
    navigate(routes.project.projectList);
  };

  const URL: string = makeV2APIURL('projects');
  const { mutate: mutatePermissions, isLoading: isLoadingPermissions } = useReactQueryMutation({
    endpoint: '',
    queryKey: ['project-permissions'],
    onSuccessCallback: (resp: PermissionApiResponse) => {
      if (resp.status === 201) {
        navigateToProjectList();
      }
    },
  });
  const { mutate, isLoading, isSuccess } = useReactQueryMutation({
    endpoint: URL,
    queryKey: ['project'],
    onSuccessCallback: (
      resp: ProjectApiResponse,
      attributes: ProjectShareFormDataTypes['collaborators']
    ) => {
      const projectId = resp.data.data.id;
      const permissionsURL: string = makeV2APIURL(`projects/${projectId}/permissions`);
      if (attributes) {
        const permissions = attributes?.map(attribute => ({
          email: attribute.email,
          role: attribute.role,
        }));
        mutatePermissions({
          axiosOptions: {},
          isMultipart: false,
          data: { data: permissions },
          dynamicEndpoint: permissionsURL,
        });
      } else {
        navigateToProjectList();
      }
    },
  });

  return { mutate, isLoading: isLoadingPermissions || isLoading, isSuccess };
};
