import { Language } from '^/types';
import { L10nDictionary } from '^/utilities/l10n';
import * as T from '^/types';

export default {
  title: {
    [Language.KO_KR]: '프로젝트 정보',
    [Language.EN_US]: 'Project Information',
  },
  support: {
    [Language.KO_KR]: '가이드',
    [Language.EN_US]: 'Support',
  },
  createNewProject: {
    [Language.EN_US]: 'Create a new project',
    [Language.KO_KR]: '새로운 프로젝트 생성',
  },
  projects: {
    [Language.EN_US]: 'Projects',
    [Language.KO_KR]: '프로젝트',
  },
  required: {
    [Language.EN_US]: 'Required *',
    [Language.KO_KR]: '필수 입력 사항입니다',
  },

  minLengthError: {
    [Language.EN_US]: 'Please enter at least 4 characters',
    [Language.KO_KR]: '최소 4자 이상 입력해주세요',
  },
  maxLengthError: {
    [Language.EN_US]: 'Description cannot exceed 300 characters',
    [Language.KO_KR]: '설명은 최대 300자입니다',
  },

  overview: {
    [Language.EN_US]: 'Overview',
    [Language.KO_KR]: '개요',
  },
  titleLabel: {
    [Language.EN_US]: 'Title *',
    [Language.KO_KR]: '제목 *',
  },
  titlePlaceholder: {
    [Language.EN_US]: 'Project Title',
    [Language.KO_KR]: '프로젝트 제목',
  },
  descriptionLabel: {
    [Language.EN_US]: 'Description',
    [Language.KO_KR]: '설명',
  },
  descriptionPlaceholder: {
    [Language.EN_US]: 'Project Description',
    [Language.KO_KR]: '프로젝트 설명',
  },
  appearance: {
    [Language.EN_US]: 'Appearance',
    [Language.KO_KR]: '디자인 설정',
  },
  coverImage: {
    [Language.EN_US]: 'Cover Image',
    [Language.KO_KR]: '커버 이미지',
  },
  manuallyUpload: {
    [Language.EN_US]: 'Manually Upload',
    [Language.KO_KR]: '수동 업로드',
  },
  projectLogo: {
    [Language.EN_US]: 'Project Logo for watermark',
    [Language.KO_KR]: '워터마크용 로고',
  },
  optional: {
    [Language.EN_US]: '(optional)',
    [Language.KO_KR]: '(선택 사항)',
  },
  logoDescription: {
    [Language.EN_US]: 'This logo will appear on the bottom of the map page.',
    [Language.KO_KR]: '로고는 지도 하단에 표시됩니다.',
  },
  // ProjectSettingForm translations
  spatialSettings: {
    [Language.EN_US]: 'Spatial Settings',
    [Language.KO_KR]: '공간 관련 설정',
  },
  coordinateSystem: {
    [Language.EN_US]: 'Coordinate System',
    [Language.KO_KR]: '좌표 시스템',
  },
  coordinateSystemPlaceholder: {
    [Language.EN_US]: 'Coordinate System',
    [Language.KO_KR]: '좌표 시스템',
  },
  coordinateSystemHelper: {
    [Language.EN_US]: 'The coordinate system you will be using in this projec',
    [Language.KO_KR]: '이 프로젝트에서 사용할 좌표 시스템입니다.',
  },
  unitSystem: {
    [Language.EN_US]: 'Unit System',
    [Language.KO_KR]: '단위 시스템',
  },
  unitSystemPlaceholder: {
    [Language.EN_US]: 'Unit System',
    [Language.KO_KR]: '단위 시스템',
  },
  unitSystemHelper: {
    [Language.EN_US]: 'The unit you would be using in this projec',
    [Language.KO_KR]: '이 프로젝트에서 사용할 단위입니다.',
  },
  nextButton: {
    [Language.EN_US]: 'Next',
    [Language.KO_KR]: '다음',
  },

  addCollaborators: {
    [Language.EN_US]: 'Add Collaborators',
    [Language.KO_KR]: '프로젝트 참여자 추가',
  },
  viewDetails: {
    [Language.EN_US]: 'View role details',
    [Language.KO_KR]: '역할 정보',
  },
  emailPlaceholder: {
    [Language.EN_US]: 'Enter email addresses separated by commas',
    [Language.KO_KR]: '쉼표로 구분된 이메일 주소를 입력하세요',
  },
  add: {
    [Language.EN_US]: 'Add',
    [Language.KO_KR]: '협력자 추가',
  },
  skipForNow: {
    [Language.EN_US]: 'Skip and Create Project',
    [Language.KO_KR]: '생략하고 바로 프로젝트 생성',
  },
  createProject: {
    [Language.EN_US]: 'Create Project',
    [Language.KO_KR]: '프로젝트 생성',
  },
  duplicateEmailError: {
    [Language.EN_US]: 'Duplicate email address',
    [Language.KO_KR]: '중복된 이메일 주소',
  },
  invalidEmailError: {
    [Language.EN_US]: 'Invalid email address',
    [Language.KO_KR]: '유효하지 않은 이메일 주소',
  },
  // ProjectPopup translations
  changeCoverImage: {
    [Language.EN_US]: 'Change Cover Image',
    [Language.KO_KR]: '커버 이미지 변경',
  },
  changeLogoImage: {
    [Language.EN_US]: 'Change Logo Image',
    [Language.KO_KR]: '로고 이미지 변경',
  },
  userRolesAndPermissions: {
    [Language.EN_US]: 'User Roles & Permissions',
    [Language.KO_KR]: '사용자 역할 및 권한',
  },
  // ChangeCoverImage translations
  upload: {
    [Language.EN_US]: 'Upload',
    [Language.KO_KR]: '업로드',
  },
  delete: {
    [Language.EN_US]: 'Delete',
    [Language.KO_KR]: '삭제',
  },
  fileTypes: {
    [Language.EN_US]: 'JPG, PNG',
    [Language.KO_KR]: 'JPG, PNG',
  },
  imageSizeError: {
    [Language.EN_US]: 'Image size must be less than 2MB',
    [Language.KO_KR]: '이미지 크기는 2MB 이하여야 합니다',
  },
  imageRecommendation: {
    [Language.EN_US]: 'Recommended: 200×200px, Max: 2MB',
    [Language.KO_KR]: '권장: 200×200px, 최대: 2MB',
  },
  information: {
    [Language.EN_US]: 'Information',
    [Language.KO_KR]: '프로젝트 정보',
  },
  settings: {
    [Language.EN_US]: 'Settings',
    [Language.KO_KR]: '설정',
  },
  share: {
    [Language.EN_US]: 'Share',
    [Language.KO_KR]: '공유',
  },
  multipleEmailsHelper: {
    [Language.EN_US]: 'Use commas (,) to enter multiple emails at once.',
    [Language.KO_KR]: '쉼표(,)를 사용하여 여러 개의 이메일을 한 번에 추가할 수 있습니다.',
  },
  noCollaborators: {
    [Language.EN_US]: 'No collaborators',
    [Language.KO_KR]: '참여자가 없습니다',
  },
  infoText: {
    [Language.EN_US]: 'You can add & manage collaborators again after creating the project',
    [Language.KO_KR]: '프로젝트 생성 후에도 언제든 다시 참여자를 관리할 수 있습니다.',
  },
  confirm: {
    [Language.EN_US]: 'Confirm',
    [Language.KO_KR]: '확인',
  },
};

export const roleNames: { [key: string]: L10nDictionary } = {
  [T.PermissionRole.ADMIN]: {
    [T.Language.KO_KR]: '관리자',
    [T.Language.EN_US]: 'Admin',
  },
  [T.PermissionRole.MEMBER]: {
    [T.Language.KO_KR]: '멤버',
    [T.Language.EN_US]: 'Member',
  },
  [T.PermissionRole.VIEWER]: {
    [T.Language.KO_KR]: '뷰어',
    [T.Language.EN_US]: 'Viewer',
  },
  [T.PermissionRole.DEMO]: {
    [T.Language.KO_KR]: '데모',
    [T.Language.EN_US]: 'Demo',
  },
  [T.PermissionRole.PILOT]: {
    [T.Language.KO_KR]: '파일럿',
    [T.Language.EN_US]: 'Pilot',
  },
};

export const permissionNames: { [key: string]: L10nDictionary } = {
  view: {
    [Language.EN_US]: 'View',
    [Language.KO_KR]: '보기',
  },
  edit: {
    [Language.EN_US]: 'Edit',
    [Language.KO_KR]: '편집',
  },
  delete: {
    [Language.EN_US]: 'Delete',
    [Language.KO_KR]: '삭제',
  },
  upload: {
    [Language.EN_US]: 'Upload',
    [Language.KO_KR]: '업로드',
  },
  share: {
    [Language.EN_US]: 'Share',
    [Language.KO_KR]: '공유',
  },
};
