import FormInput from '^/components/molecules/FormInput';
import FormTextArea from '^/components/molecules/FormTextArea';
import React from 'react';
import styled from 'styled-components';
import { AddProjectFormDataTypes } from './addProjectFormUtils';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import CameraIcon from '^/assets/icons/camera.svg';
import { usePopupStore } from '^/store/popup';
import * as T from '^/types';
import { HorizontalDivider } from '^/components/atoms/ContentsListItem';
import Button from '^/components/atoms/Button';
import theme from '^/theme';
import { useL10n } from '^/hooks/useL10n';
import Text from './text';
import WhiteChvIcon from '^/assets/icons/white-chv.svg';

const Section = styled.section({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  paddingTop: '20px',
});

const SectionTitle = styled.h2({
  fontSize: '18px',
  fontWeight: '600',
  color: '#333',
  paddingBottom: '12px',
  paddingTop: '12px',
  width: '160px',
});

// const RadioGroup = styled.div({
//   display: 'flex',
//   gap: '16px',
//   marginTop: '20px',
//   marginBottom: '10px',
// });

// const RadioInput = styled.input({
//   appearance: 'none',
//   margin: 0,
//   width: '20px',
//   height: '20px',
//   border: '2px solid #ccc',
//   borderRadius: '50%',
//   transition: 'all 0.2s ease-in-out',
//   position: 'relative',
//   cursor: 'pointer',

//   '&:checked': {
//     borderColor: '#007AFF',
//     '&::after': {
//       content: '""',
//       display: 'block',
//       width: '12px',
//       height: '12px',
//       backgroundColor: '#007AFF',
//       borderRadius: '50%',
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//     },
//   },

//   '&:hover': {
//     borderColor: '#007AFF',
//   },
// });

// const RadioLabel = styled.label({
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
//   cursor: 'pointer',
//   fontSize: '14px',
//   color: '#333',
// });

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const FieldsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  flexGrow: 1,
  paddingTop: '12px',
  paddingBottom: '12px',
});

const ImageUploadArea = styled.div({
  width: '100%',
  height: '200px',
  border: `1px solid ${theme.colorTokens.lineCool}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  overflow: 'hidden',
  backgroundColor: theme.colorTokens.bgLightCoolGray,
  marginTop: '8px',

  '&:hover': {
    borderColor: theme.colorTokens.lineCool,
    backgroundColor: theme.colorTokens.bgLightGray,
  },
});

const LogoUploadArea = styled(ImageUploadArea)({
  height: '120px',
  width: '120px',
});

const ImagePreview = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
});

const LogoPreview = styled(ImagePreview)({
  borderRadius: '4px',
});

const SubText = styled.p({
  color: theme.colorTokens.textCool,
  fontSize: '14px',
  marginTop: '8px',
});

const OptionalText = styled.span({
  color: theme.colorTokens.textCool,
  fontSize: '14px',
  marginLeft: '8px',
});

// const PreviewWrapper = styled.div({
//   paddingLeft: '30px',
//   width: '276px',
// });

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Wrapper = styled.div({});

interface ProjectInformationFormProps {
  register: UseFormRegister<AddProjectFormDataTypes>;
  errors: FieldErrors<AddProjectFormDataTypes>;
  watch: UseFormWatch<AddProjectFormDataTypes>;
  isValid: boolean;
  setCurrentTab(currentTab: number): void;
  setCompletedTabs(completedTabs: number[]): void;
}

const ProjectInformationForm: React.FC<ProjectInformationFormProps> = ({
  register,
  errors,
  watch,
  isValid,
  setCurrentTab,
  setCompletedTabs,
}) => {
  const [l10n] = useL10n();
  const logo = watch('logo');
  // const thumbnail = watch('thumbnail');
  const setPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupOptions = usePopupStore(s => s.setPopupOptions);
  const logoPreview = logo ? URL.createObjectURL(logo) : null;
  // const coverPreview = thumbnail ? URL.createObjectURL(thumbnail) : null;

  const onClickHandler = (type: T.ProjectPagePopupType) => {
    setPopup(T.ContentPageMinorPopupType.PROJECT_PAGE_POPUP);
    setPopupOptions({ projectPagePopupType: type });
  };

  const onNextClickHandler = () => {
    setCurrentTab(2);
    setCompletedTabs([1]);
  };

  return (
    <Root>
      <Section>
        <SectionTitle>{l10n(Text.overview)}</SectionTitle>
        <FieldsWrapper>
          <FormInput
            name="title"
            label={l10n(Text.titleLabel)}
            register={register}
            error={errors.title}
            placeholder={l10n(Text.titlePlaceholder)}
          />
          <FormTextArea
            placeholder={l10n(Text.descriptionPlaceholder)}
            name="description"
            label={l10n(Text.descriptionLabel)}
            register={register}
          />
        </FieldsWrapper>
      </Section>
      <HorizontalDivider />
      <Section>
        <SectionTitle>{l10n(Text.appearance)}</SectionTitle>
        <FieldsWrapper>
          {/* <Wrapper>
            <div>{l10n(Text.coverImage)}</div>
            <RadioGroup>
              <RadioLabel>
                <RadioInput type="radio" value="manual" />
                {l10n(Text.manuallyUpload)}
              </RadioLabel>
            </RadioGroup>

            <PreviewWrapper
              onClick={() => onClickHandler(T.ProjectPagePopupType.PROJECT_COVER_IMAGE)}
            >
              <ImageUploadArea>
                {coverPreview ? (
                  <ImagePreview src={coverPreview} alt="Cover preview" />
                ) : (
                  <CameraIcon />
                )}
              </ImageUploadArea>
            </PreviewWrapper>
          </Wrapper> */}

          <Wrapper>
            <Wrapper>
              {l10n(Text.projectLogo)}
              <OptionalText>{l10n(Text.optional)}</OptionalText>
            </Wrapper>
            <SubText>{l10n(Text.logoDescription)}</SubText>
            <LogoUploadArea
              onClick={() => onClickHandler(T.ProjectPagePopupType.PROJECT_LOGO_IMAGE)}
            >
              {logoPreview ? <LogoPreview src={logoPreview} alt="Logo preview" /> : <CameraIcon />}
            </LogoUploadArea>
          </Wrapper>
        </FieldsWrapper>
      </Section>
      <HorizontalDivider />
      <ButtonWrapper>
        <Button
          size="large"
          type="button"
          variant="primary"
          disabled={!isValid}
          customStyles={{ gap: '4px' }}
          onClick={onNextClickHandler}
        >
          {l10n(Text.nextButton)} <WhiteChvIcon />
        </Button>
      </ButtonWrapper>
    </Root>
  );
};

export default ProjectInformationForm;
