import { Language } from '^/types';

export default {
  cancel: {
    [Language.EN_US]: 'Cancel',
    [Language.KO_KR]: '취소',
  },
  apply: {
    [Language.EN_US]: 'Apply',
    [Language.KO_KR]: '적용',
  },
};
