import { SUPPORT_EMAIL } from '^/constants/defaultText';
import { Language } from '^/types';

export default {
  photoRequest: {
    [Language.EN_US]: 'Photo Request',
    [Language.KO_KR]: '사진 요청',
  },
  beta: {
    [Language.EN_US]: '(Beta)',
    [Language.KO_KR]: '(베타)',
  },
  requestPhoto: {
    [Language.EN_US]: 'Request Photo',
    [Language.KO_KR]: '사진 요청하기',
  },
  itemSelectedTooltip: (items: number) => ({
    [Language.KO_KR]: `${items}장 선택됨`,
    [Language.EN_US]: `${items} ${items > 1 ? 'items' : 'item'} selected`,
  }),
  alreadyRequested: {
    [Language.EN_US]: `You already requested for free trial. please contact us for purchase options. ${SUPPORT_EMAIL}`,
    [Language.KO_KR]: `이미 무료 요청 횟수를 모두 사용 하셨습니다. 구매를 통해 추가 요청이 필요하신 경우에는 비즈니스 문의 부탁드립니다. ${SUPPORT_EMAIL}`,
  },
  freeLimit: {
    [Language.EN_US]: `The free plan allows up to 10 captures. If you need additional captures, please contact us for purchase options. ${SUPPORT_EMAIL}`,
    [Language.KO_KR]: `무료로 캡쳐 가능한 갯수는 10개이며, 구매를 통해 추가 요청이 필요하시면 비즈니스 문의 부탁드립니다. ${SUPPORT_EMAIL}`,
  },
  tryCapturing: {
    [Language.EN_US]: 'Try capturing the viewpoint.',
    [Language.KO_KR]: '촬영을 원하는 뷰를 저장하세요.',
  },
  easilyRequest: {
    [Language.EN_US]: 'You can easily request and receive photos by specifying a viewpoint.',
    [Language.KO_KR]: '특정 뷰포인트를 캡쳐하여 현장에 직접 가지 않고 손쉽게 사진을 받아 보세요.',
  },
  successfullyRequested: {
    [Language.EN_US]: 'Successfully requested',
    [Language.KO_KR]: '성공적으로 요청완료 되었습니다.',
  },
  requestCompleted: {
    [Language.EN_US]: 'Request completed',
    [Language.KO_KR]: '요청 완료',
  },
  minMaxDays: (min: number, max: number) => ({
    [Language.EN_US]: `This will take a minimum of ${min} days and a maximum of ${max} weeks.`,
    [Language.KO_KR]: `요청한 사진이 업로드 되기까지 ${min}일에서 길게는 ${max}주까지 소요될 수 있습니다.`,
  }),
  checkInPhotoAlbum: {
    [Language.EN_US]: 'Your photos will appear in the album once processing is finished.',
    [Language.KO_KR]: '처리가 완료되면 사진이 앨범에 표시됩니다.',
  },
};
