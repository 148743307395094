/* eslint-disable max-lines */
import _ from 'lodash-es';
import React, {
  Fragment,
  ReactNode,
  SyntheticEvent,
  MouseEvent as ReactMouseEvent,
  FC,
  useRef,
  useState,
  useEffect,
} from 'react';
import styled, { CSSObject } from 'styled-components';

import FaIcon from '^/components/atoms/FaIcon';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { useClickOutside, UseL10n, useL10n } from '^/hooks';
import { l10n } from '^/utilities/l10n';
import lang from './text';
import Chevron from '^/assets/icons/contents-list/chevron.svg';
import colorTokens from '^/theme/colors/color-tokens';
import theme from '^/theme';

export interface Option {
  text: string;
  value: number | string;
  helperText?: string;
}

export const createOption: (text: string) => Option = text => ({
  text,
  value: text,
});

export const createOptions: (texts: string[]) => Option[] = texts => texts.map(createOption);

const borderWidth: number = 1;

const Root = styled.div<{ isCompactSize: boolean }>(({ isCompactSize }) => ({
  flexShrink: 0,
  width: '100%',
  height: isCompactSize ? '26px' : 'auto',
}));

interface WrapperProps {
  readonly zIndex: number;
}
const Wrapper = styled.div<WrapperProps & { isCompactSize: boolean }>(
  ({ zIndex, isCompactSize }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',

    color: theme.colorTokens.textCoolDark,

    '& *': {
      lineHeight: isCompactSize ? '26px' : '39px',

      ':not(.fa)': {
        fontSize: '14px',
        color: 'inherit',
      },
    },

    zIndex,
  })
);

interface MainButtonProps {
  readonly mainButtonStyle?: CSSObject;
  readonly error?: boolean;
  readonly disabled?: boolean;
}
const MainButton = styled.button<MainButtonProps>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    paddingLeft: '12px',
    paddingRight: '12px',

    borderWidth: `${borderWidth}px`,
    borderStyle: 'solid',
    borderColor: colorTokens.lineCoolDarker,
    borderRadius: '4px',

    backgroundColor: palette.white.toString(),
    boxShadow: '0px 1px 2px 0px rgba(55, 93, 251, 0.08)',

    textAlign: 'left',
  },
  ({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  ({ error, mainButtonStyle }) => {
    const buttonStyle: CSSObject =
      mainButtonStyle !== undefined
        ? {
            ...mainButtonStyle,
            '&&&': mainButtonStyle,
          }
        : {};

    return {
      ...buttonStyle,
      borderColor: (error ? palette.error : palette.border).toString(),
    };
  }
);

const SearchIconWrapper = styled.span({
  marginLeft: '10px',
});

interface ValueWrapperProps {
  isDropDownOpen: boolean;
  isCompactSize: boolean;
}
const ValueWrapper = styled.span<ValueWrapperProps>(({ isCompactSize, isDropDownOpen }) => ({
  flexGrow: 1,
  textTransform: 'capitalize',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  pointerEvents: 'none',

  lineHeight: isCompactSize ? '26px' : '39px',

  color: isDropDownOpen ? 'var(--color-theme-primary) !important' : `auto`,
}));

const ValueEditor = styled.input({
  flexGrow: 1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  backgroundColor: 'transparent',
});

interface CaretProps {
  readonly caretStyle?: CSSObject;
}

interface MenuProps {
  readonly menuStyle?: CSSObject;
  readonly height?: CSSObject['height'];
}
export const Menu = styled.ul.attrs({
  'data-testid': 'dropdown-menu',
})<MenuProps>(
  {
    position: 'absolute',
    zIndex: 1,
    boxSizing: 'border-box',
    top: '105%',
    minWidth: '100%',
    maxHeight: '300px',
    marginTop: `${-borderWidth}px`,

    borderWidth: `${borderWidth}px`,
    borderStyle: 'solid',
    borderColor: palette.border.toString(),
    borderRadius: '4px',
    borderTop: 0,

    backgroundColor: palette.white.toString(),

    overflow: 'auto',
    // filter: `drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.09)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25))`,
  },
  ({ menuStyle, height }) =>
    menuStyle !== undefined
      ? {
          ...menuStyle,
          '&&&': menuStyle,
          height,
        }
      : { height }
);

const CreatingMenu = styled(Menu)({
  position: 'relative',
});

const ItemCSS: CSSObject = {
  display: 'block',

  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingLeft: '10px',

  fontWeight: 300,

  textAlign: 'left',
  backgroundColor: palette.white.toString(),

  cursor: 'pointer',
};

const ItemLabel = styled.li.attrs({
  'data-testid': 'dropdown-item-label',
})<ItemProps>({
  ...ItemCSS,
  fontWeight: 500,
  pointerEvents: 'none',
});

interface ItemProps {
  readonly itemStyle?: CSSObject;
}

const Item = styled.li.attrs({
  'data-testid': 'dropdown-item',
})<ItemProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'capitalize',
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    padding: '10px',

    textAlign: 'left',
    backgroundColor: theme.colors.white[100],

    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors.lightBlue[75],
      '.helperText': {
        color: `#959595 !important`,
      },
      '.text': {
        color: `#737373`,
      },
    },
  },
  ({ itemStyle }) =>
    itemStyle !== undefined
      ? {
          ...itemStyle,
          '&&&': itemStyle,
        }
      : {}
);

const Text = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  minWidth: '77px',
  fontSize: '15px',
  lineHeight: '16px',
  color: isSelected
    ? `${theme.colorTokens.highlightSkyblue} !important`
    : theme.colorTokens.textCoolDark,
}));

const HelperText = styled.div({
  color: `#C9C9C9 !important`,
  paddingRight: '.5rem',
  width: 'max-content',
  textAlign: 'start',
  fontSize: '11px',
  lineHeight: '16px',
});

const CreateNewEntry = styled.div({
  position: 'absolute',
  boxSizing: 'border-box',
  backgroundColor: palette.white.toString(),
  border: '1px solid #cccccc',
  width: '279px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const SelectEntryLabel = styled.div({
  color: '#AAAAAA',
  display: 'flex',
  justifyContent: 'center',
  lineHeight: '15.02px',
  whiteSpace: 'nowrap',
  fontSize: '10.5px !important',
  height: '16px',
  padding: '0 7px',
});

const SelectEntryButton = styled.button({
  color: `${dsPalette.themePrimary.toString()} !important`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${dsPalette.themePrimary.toString()}`,
  width: 'calc(100% - 19px)',
  height: '32px',
  margin: '16px 9.5px 0 9.5px',
  borderRadius: '8px',
  backgroundColor: palette.dropdown.createBackground.toString(),
  fontSize: '14px !important',
  fontWeight: '500',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: `${dsPalette.themePrimary.toString()} !important`,
    color: `${dsPalette.white.toString()} !important`,
  },
});

const SelectEntryContainer = styled.div({
  boxSizing: 'border-box',
  height: '92px',
  paddingTop: '16px',
});

const itemLabelValue: string = '[]';

interface StyleProps extends WrapperProps, MainButtonProps, CaretProps, MenuProps, ItemProps {}
export interface Props extends StyleProps {
  readonly value: number | string | null | undefined;
  readonly options: Option[];
  readonly placeHolder: string;
  readonly className?: string;
  readonly error?: boolean;
  readonly isSearchEnable?: boolean;
  readonly trackAction?: string;
  readonly trackLabel?: string;
  readonly isCompactSize?: boolean;
  readonly isCreatingNewEntry?: boolean;
  readonly hasCreatedNewEntry?: boolean;
  readonly displaySearchIcon?: boolean;
  onClick(option: Option, index: number): void;
  onCreate?(text: string): void;
}

export interface State {
  readonly isOpen: boolean;
  readonly keyword: string;
  readonly filteredOptions: Option[];
}

/**
 * Dropdown element
 */
const Dropdown: FC<Props> = ({
  options,
  itemStyle,
  value,
  isSearchEnable,
  zIndex,
  mainButtonStyle,
  error,
  displaySearchIcon = false,
  placeHolder,
  className,
  trackAction,
  trackLabel,
  menuStyle,
  height,
  onClick,
  onCreate,
  isCompactSize = false,
  disabled,
  isCreatingNewEntry,
  hasCreatedNewEntry,
}) => {
  const [, language]: UseL10n = useL10n();
  const rootRef = useRef<HTMLDivElement>(null);
  const [dropdownState, setDropdownState] = useState<State>({
    isOpen: false,
    keyword: '',
    filteredOptions: options,
  });

  useEffect(() => {
    if (hasCreatedNewEntry) {
      setDropdownState({ isOpen: false, keyword: '', filteredOptions: options });
    }
  }, [hasCreatedNewEntry]);

  useClickOutside({
    ref: rootRef,
    callback() {
      setDropdownState({ ...dropdownState, isOpen: false });
    },
  });

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    document.addEventListener('touchend', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
      document.removeEventListener('touchend', handleDocumentClick, false);
    };
  }, []);

  useEffect(() => {
    setDropdownState({ ...dropdownState, filteredOptions: options });
  }, []);

  const optionToElement: (option: Option, index: number) => ReactNode = (option, index) => {
    const onClickEvent: (event: SyntheticEvent) => void = event =>
      handleItemClick(event, option, index);

    const item: ReactNode =
      option.value === itemLabelValue ? (
        <ItemLabel>{option.text}</ItemLabel>
      ) : (
        <Item
          className={option.value === value ? 'active' : undefined}
          itemStyle={itemStyle}
          onClick={onClickEvent}
        >
          <Text className="text" isSelected={option.text === selectedValue}>
            {option.text}
          </Text>
          {option.helperText && <HelperText className="helperText">{option.helperText}</HelperText>}
        </Item>
      );

    return <Fragment key={index}>{item}</Fragment>;
  };

  const createNewEntryEvent = () => {
    if (onCreate && dropdownState.keyword.length > 0) {
      onCreate(dropdownState.keyword);
    }
  };

  const showCreateMenu: boolean =
    _.trim(dropdownState.keyword).length > 0 &&
    !dropdownState.filteredOptions.find(
      option =>
        _.trim(_.toLower(option.text.toString())) === _.trim(_.toLower(dropdownState.keyword))
    );

  const createNewEntry: ReactNode = dropdownState.isOpen && isCreatingNewEntry && (
    <CreateNewEntry>
      <CreatingMenu menuStyle={menuStyle} height={height}>
        {dropdownState.filteredOptions.map(optionToElement)}
      </CreatingMenu>
      {showCreateMenu && (
        <SelectEntryContainer>
          <SelectEntryLabel>
            {l10n(lang.create, language)}: {_.trimEnd(dropdownState.keyword)}
          </SelectEntryLabel>
          <SelectEntryButton onClick={createNewEntryEvent}>
            + {_.trimEnd(dropdownState.keyword)}
          </SelectEntryButton>
        </SelectEntryContainer>
      )}
    </CreateNewEntry>
  );

  const handleDocumentClick: ({ target }: MouseEvent) => void = ({ target }) => {
    if (
      rootRef.current !== null &&
      !rootRef.current.contains(target as Node) &&
      dropdownState.isOpen
    ) {
      setDropdownState({ ...dropdownState, isOpen: false });
    }
  };

  const handleButtonClick: (e: ReactMouseEvent) => void = e => {
    if (dropdownState.isOpen && dropdownState.keyword.length > 0 && e.nativeEvent.detail === 0) {
      return;
    }
    setDropdownState({ ...dropdownState, isOpen: !dropdownState.isOpen });
  };

  const handleSearchChange: (event: SyntheticEvent<HTMLInputElement>) => void = event => {
    const keyword: string = _.toLower(event.currentTarget.value.trim());

    if (isCreatingNewEntry && keyword.length > 25) {
      return;
    }

    /**
     * @info dirty trick to solve the AutoFill problem from Browsers
     */
    const matches: RegExpMatchArray | null = keyword.match(/[a-zA-Z\s]+/gi);
    if (keyword.length > 0 && matches && matches.length > 1) {
      return;
    }

    const hasKeyword: (option: Option) => boolean = ({ text, value: keywordValue }) =>
      _.trimEnd(_.toLower(text)).indexOf(keyword) > -1 ||
      _.trimEnd(_.toLower(keywordValue.toString())).indexOf(keyword) > -1;
    const filteredOptions: Option[] = keyword.length === 0 ? options : options.filter(hasKeyword);
    setDropdownState({
      ...dropdownState,
      keyword: event.currentTarget.value,
      filteredOptions,
    });
  };

  const handleItemClick: (event: SyntheticEvent, option: Option, index: number) => void = (
    event,
    option,
    index
  ) => {
    setDropdownState({ ...dropdownState, isOpen: false });
    onClick(option, index);

    /**
     * @desc this preventDefault help the Dropdown react correctly when put it inside of a Label
     */
    event.preventDefault();
  };

  const selectedOption: Option | undefined = options.find(
    ({ value: optionValue }) => optionValue === value
  );
  const selectedValue: string = selectedOption === undefined ? placeHolder : selectedOption.text;

  const menu: ReactNode = dropdownState.isOpen ? (
    <Menu menuStyle={menuStyle} height={height}>
      {dropdownState.filteredOptions.map(optionToElement)}
    </Menu>
  ) : undefined;

  const searchIcon: ReactNode =
    displaySearchIcon && (selectedOption === undefined || dropdownState.isOpen) ? (
      <SearchIconWrapper>
        <FaIcon faNames="search" fontSize="15px" data-testid="dropdown-search-icon" />
      </SearchIconWrapper>
    ) : undefined;

  const valueViewer: ReactNode =
    dropdownState.isOpen && isSearchEnable ? (
      <ValueEditor
        value={dropdownState.keyword}
        autoFocus={true}
        onChange={handleSearchChange}
        data-testid="dropdown-value-input"
      />
    ) : (
      <ValueWrapper
        isCompactSize={isCompactSize}
        isDropDownOpen={dropdownState.isOpen}
        data-testid="dropdown-value"
      >
        {selectedValue}
      </ValueWrapper>
    );

  return (
    <Root
      className={className}
      ref={rootRef}
      data-ddm-track-action={trackAction}
      data-ddm-track-label={trackLabel}
      isCompactSize={isCompactSize}
    >
      <Wrapper isCompactSize={isCompactSize} zIndex={zIndex + (dropdownState.isOpen ? 1 : 0)}>
        <MainButton
          mainButtonStyle={mainButtonStyle}
          error={error}
          onClick={handleButtonClick}
          type="button"
          data-testid="dropdown-mainbutton"
          disabled={disabled}
        >
          {searchIcon}
          {valueViewer}
          <Chevron data-testid="dropdown-caret" />
        </MainButton>
        {isCreatingNewEntry ? createNewEntry : menu}
      </Wrapper>
    </Root>
  );
};

export default Dropdown;
