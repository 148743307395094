import React from 'react';
import styled from 'styled-components';
import colorTokens from '^/theme/colors/color-tokens';
import theme from '^/theme';
import { HorizontalDivider } from '^/components/atoms/ContentsListItem';
import Button from '^/components/atoms/Button';
import { L10nDictionary } from '^/utilities/l10n';
import { useL10n } from '^/hooks';
import Text from './text';
interface Permission {
  name: L10nDictionary;
  isEnabled: boolean;
}

interface Role {
  name: L10nDictionary;
  permissions: Permission[];
}

interface Props {
  roles: Role[];
}

const Container = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const Title = styled.p`
  color: ${colorTokens.textCoolLight};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const RoleContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const RoleCard = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  transition: all 0.2s ease;
`;

const RoleName = styled.span`
  color: ${colorTokens.textCoolDark};
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: 70px;
`;

const PermissionList = styled.div`
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  font-size: 12px;
  border-radius: 5px;
  color: ${colorTokens.textCoolDark};
  background-color: ${colorTokens.bgLightGray};
`;
const VerticalDivider = styled.div({
  width: '2px',
  backgroundColor: theme.colorTokens.lineCool,
});

const RoleContainerWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RoleInformation: React.FC<Props> = ({ roles }) => {
  const [l10n] = useL10n();
  return (
    <Container>
      <Title>User Roles & Permissions</Title>
      <RoleContainer>
        <VerticalDivider />
        <RoleContainerWrapper>
          {roles.map(role => (
            <RoleCard key={l10n(role.name)}>
              <RoleName>{l10n(role.name)}</RoleName>
              <PermissionList>
                {role.permissions.map((permission, index) =>
                  index === role.permissions.length - 1
                    ? `${l10n(permission.name)}`
                    : `${l10n(permission.name)}, `
                )}
              </PermissionList>
            </RoleCard>
          ))}
        </RoleContainerWrapper>
      </RoleContainer>
      <HorizontalDivider />

      <ButtonWrapper>
        <Button variant="secondary" size="large">
          {l10n(Text.confirm)}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default RoleInformation;
