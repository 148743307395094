import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ProjectInformationForm from './ProjectInformationForm';
import ProjectSettingForm from './ProjectSettingForm';
import ProjectShareForm from './ProjectShare';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddProjectFormDataTypes, addProjectValidationSchema } from './addProjectFormUtils';
import ProjectPopups from './ProjectPopups';
import * as T from '^/types';
import { useAddProject } from './useAddProject';
import { useL10n } from '^/hooks/useL10n';
import theme from '^/theme';
import styled from 'styled-components';

import { StepTabs } from '^/components/molecules/StepTabs';

import Text from './text';
import routes from '^/constants/routes';
import { BackToPageButton } from '^/components/molecules/BackToPageButton';

const Root = styled.section({
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '40px',
  paddingLeft: '60px',
  borderBottom: `1px solid ${theme.colorTokens.lineGray}`,
  gap: '16px',
});

const Title = styled.h1({
  fontSize: '24px',
  lineHeight: '150%',
  fontWeight: '700',
});

const FormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 60px 20px 80px',
  maxWidth: '800px',
});

const AddProjectForms = () => {
  const [l10n, language] = useL10n();
  const [currentTab, setCurrentTab] = useState(1);
  const [errorTabs, setErrorTabs] = useState<number[]>([]);
  const [completedTabs, setCompletedTabs] = useState<number[]>([]);

  const TABS = [
    { id: 1, label: l10n(Text.information) },
    { id: 2, label: l10n(Text.settings) },
    { id: 3, label: l10n(Text.share) },
  ];

  const onTabClick = (currentTabId: number) => {
    setCurrentTab(currentTabId);
    const newCompletedTabs = Array.from({ length: currentTabId - 1 }, (_, i) => i + 1);
    setCompletedTabs(newCompletedTabs);
  };
  const {
    watch,
    register,
    setValue,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddProjectFormDataTypes>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(addProjectValidationSchema(currentTab, language)) as any, // multi-step form
    defaultValues: {
      // planId: undefined,
      title: '',
      description: '',
      logo: undefined,
      unit: T.UnitType.METRIC,
      coordinateSystem: T.ProjectionEnum.WGS84_EPSG_4326_LL,
    },
  });

  const { mutate, isLoading } = useAddProject();

  const onSubmit = handleSubmit(async () => {
    const { collaborators, isCollaboratorsSkipped, ...projectPayload } = getValues();
    mutate({
      axiosOptions: {},
      isMultipart: true,
      data: projectPayload,
      callbackAttributes: isCollaboratorsSkipped ? undefined : collaborators,
    });
  });

  useEffect(() => {
    if (currentTab !== 1) {
      void trigger();
    }
  }, [currentTab]);

  useEffect(() => {
    if (errors.title || errors.description) {
      setErrorTabs([...errorTabs, 1]);
    } else {
      setErrorTabs([]);
    }
  }, [errors, currentTab]);

  return (
    <>
      <Root>
        <TopBar>
          <BackToPageButton label={l10n(Text.projects)} route={routes.project.projectList} />
          <Title>{l10n(Text.createNewProject)}</Title>
          <StepTabs
            tabs={TABS}
            errorTabs={errorTabs}
            onTabClick={onTabClick}
            currentTab={currentTab}
            completedTabs={completedTabs}
          />
        </TopBar>
        <FormWrapper>
          <form onSubmit={onSubmit}>
            {currentTab === 1 && (
              <ProjectInformationForm
                watch={watch}
                errors={errors}
                isValid={isValid}
                register={register}
                setCurrentTab={setCurrentTab}
                setCompletedTabs={setCompletedTabs}
              />
            )}
            {currentTab === 2 && (
              <ProjectSettingForm
                watch={watch}
                errors={errors}
                isValid={isValid}
                setValue={setValue}
                setCurrentTab={setCurrentTab}
                setCompletedTabs={setCompletedTabs}
                completedTabs={completedTabs}
              />
            )}
            {currentTab === 3 && (
              <ProjectShareForm
                watch={watch}
                isValid={isValid}
                setValue={setValue}
                isLoading={isLoading}
              />
            )}
          </form>
        </FormWrapper>
      </Root>
      <ProjectPopups setValue={setValue} />
    </>
  );
};

export default AddProjectForms;
