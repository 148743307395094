import * as T from '^/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface PopupOptions {
  popupType?: T.ContentPagePopupType;
  photoType?: Exclude<
    T.AttachmentType,
    | T.AttachmentType.SOURCE
    | T.AttachmentType.THREE_SIXTY_STITCHED
    | T.AttachmentType.THREE_SIXTY_VSLAM
  >;
  uploadType?: T.PhotoType;
  myPagePopupType?: T.MyPagePopupContentTypes;
  projectPagePopupType?: T.ProjectPagePopupType;
}
interface PopupStoreState {
  minorPopup?: {
    type: T.ContentPageMinorPopupType;
  };
  popupOptions: PopupOptions;
}
interface PopupStoreActions {
  setMinorPopup(type?: T.ContentPageMinorPopupType): void;
  setPopupOptions(options: PopupOptions): void;
}

const initialState: PopupStoreState = {
  minorPopup: undefined,
  popupOptions: {
    popupType: undefined,
    photoType: undefined,
    uploadType: undefined,
    myPagePopupType: undefined,
    projectPagePopupType: undefined,
  },
};

export const usePopupStore = create<PopupStoreState & PopupStoreActions>()(
  devtools(set => ({
    ...initialState,

    setPopupOptions: (options: Partial<PopupOptions>) => {
      set({ popupOptions: { ...initialState.popupOptions, ...options } });
    },

    setMinorPopup: (type?: T.ContentPageMinorPopupType) => {
      set({ minorPopup: type ? { type } : undefined });
    },
  }))
);
