import React, { PropsWithChildren } from 'react';
import theme from '^/theme';
import styled, { CSSObject } from 'styled-components';
import { Title, TitleValuePair, Value } from '^/components/organisms/MyPage/styles';
import ChevronRightSvg from '^/assets/icons/mypage/chevron-right.svg';

const TupleContainer = styled.div<{ nonEditable?: boolean }>(({ nonEditable = false }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: nonEditable ? 'default' : 'pointer',
  padding: '12px',
  borderRadius: '8px',
  width: '100%',
  ...(!nonEditable && {
    ':hover': {
      backgroundColor: theme.colors.coolGray[50],
    },
  }),
}));

interface Props {
  title?: string;
  value?: string;
  boldValue?: boolean;
  onClick?(): void;
  titleCustomStyles?: CSSObject;
  valueCustomStyles?: CSSObject;
  nonEditable?: boolean;
}
export function Tuple({
  title,
  value,
  onClick,
  boldValue,
  titleCustomStyles,
  valueCustomStyles,
  children,
  nonEditable,
}: PropsWithChildren<Props>) {
  return (
    <TupleContainer onClick={onClick} nonEditable={nonEditable}>
      <TitleValuePair>
        {title && <Title customStyles={titleCustomStyles}>{title}</Title>}
        {value && (
          <Value bold={boldValue} customStyles={valueCustomStyles}>
            {value}
          </Value>
        )}
        {children}
      </TitleValuePair>
      {!nonEditable && <ChevronRightSvg />}
    </TupleContainer>
  );
}
