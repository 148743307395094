import theme from '^/theme';
import React from 'react';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

const InputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
});

const Label = styled.label({
  fontSize: '15px',
  fontWeight: '400',
  color: theme.colorTokens.textCoolDarker,
});

interface StyledInputProps {
  $hasError?: boolean;
}

const StyledInput = styled.textarea<StyledInputProps>(({ $hasError }) => ({
  height: '44px',
  padding: '16px',
  fontSize: '16px',
  border: `1px solid ${$hasError ? theme.colors.pointRed[500] : theme.colors.coolGray[200]}`,
  borderRadius: '8px',
  outline: 'none',
  transition: 'all 0.2s ease',
  backgroundColor: theme.colors.neutralGray[50],
  resize: 'vertical',

  '&:focus': {
    borderColor: theme.colors.vividBlue[500],
    boxShadow: `0 0 0 2px ${theme.colors.vividBlue[100]}`,
  },

  '&::placeholder': {
    color: theme.colors.neutralGray[400],
  },
}));

const ErrorMessage = styled.span({
  fontSize: '12px',
  color: theme.colors.pointRed[500],
  marginTop: '-4px',
});

interface FormTextAreaProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  placeholder?: string;
  type?: string;
  register?: UseFormRegister<T>;
  error?: FieldError;
}

export default function FormTextArea<T extends FieldValues>({
  label,
  name,
  placeholder,
  register,
  error,
}: FormTextAreaProps<T>) {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <StyledInput
        placeholder={placeholder}
        $hasError={Boolean(error)}
        {...(register && name ? register(name) : {})}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </InputContainer>
  );
}
