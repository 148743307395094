import { config } from '^/config';
import { AuthHeader, makeAuthHeader, makeV2APIURL } from '^/store/duck/API';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import {
  FinishPostPhoto,
  FinishUploadPhotos,
  GetDroneVideos,
  PostBulkPhotoUploadResponse,
} from '^/store/duck/Photos';
import { useVslamAnd360Store } from '^/store/vslamAnd360Store';
import * as T from '^/types';
import axios, { AxiosProgressEvent } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

export interface TVslamIndoorFiles {
  videoFile: File;
}

interface Upload360IndoorProps {
  readonly vslamIndoorFiles: TVslamIndoorFiles;
}

/**
 * @author Ebraj Gg
 * Listing the API hit steps, so that it will be easier to track...
 */
export function use360IndoorDataUpload() {
  const dispatch = useDispatch();
  const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
  const Auth = useSelector((s: T.State) => s.Auth);
  const slug = useSelector((s: T.State) => s.PlanConfig.config?.slug);
  const setGeoreferencingMapDetails = useVslamAnd360Store(
    state => state.setGeoreferencingMapDetails
  );

  const authHeader: AuthHeader | undefined = makeAuthHeader(Auth, slug);

  async function upload360IndoorData({ vslamIndoorFiles }: Upload360IndoorProps) {
    if (projectId === undefined || authHeader === undefined) {
      dispatch(ChangeAuthedUser({}));
      return;
    }

    try {
      const originalFilename = vslamIndoorFiles.videoFile.name;

      const isKSAregion = config.region === T.Region.KSA;
      let URL: string;
      if (isKSAregion) {
        URL = makeV2APIURL('projects', projectId, 'videos', `new?fileName=${originalFilename}`);
      } else {
        URL = makeV2APIURL('projects', projectId, 'videos', 'new');
      }
      const header = makeAuthHeader(Auth, slug);

      /**
       * HIT 1 -> Getting the URL to upload video to S3 Bucket...
       */
      const res = await axios.get(URL, { headers: header });
      const metaResponse: PostBulkPhotoUploadResponse = res.data;
      const randomString = Math.random().toString(36).substring(15);

      const fileExtension = originalFilename.split('.').pop();
      const fileName = encodeURIComponent(originalFilename.split('/').pop()!);
      const newFilename = `${fileName.split('.')[0]}_${randomString}.${fileExtension}`;

      let keyFileName: string;
      if (isKSAregion) {
        // eslint-disable-next-line no-template-curly-in-string
        keyFileName = metaResponse.key.replace('${filename}', newFilename);
      } else {
        // eslint-disable-next-line no-template-curly-in-string
        keyFileName = metaResponse.fields.key.replace('${filename}', newFilename);
      }
      const uploadFormData = new FormData();
      if (!isKSAregion) {
        Object.entries(metaResponse.fields).forEach(([key, value]) => {
          if (key === 'key') {
            uploadFormData.append(key, keyFileName);
          } else {
            uploadFormData.append(key, value);
          }
        });
        uploadFormData.append('file', vslamIndoorFiles.videoFile);
      }

      const modifiedFile = new File([vslamIndoorFiles.videoFile], newFilename, {
        type: vslamIndoorFiles.videoFile.type,
      });

      /**
       * HIT 2 -> Uploading the files to obtained s3 bucket URL...
       */
      setGeoreferencingMapDetails({
        isVslamVideoUploading: true,
      });
      const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setGeoreferencingMapDetails({
            uploadVslamVideoProgressCount: progress,
          });
        }
      };
      const uploadRequest = !isKSAregion
        ? axios.post(metaResponse.url, uploadFormData, {
            onUploadProgress: handleUploadProgress,
          })
        : axios.put(metaResponse.url, modifiedFile, {
            headers: { 'Content-Type': modifiedFile.type },
            onUploadProgress: handleUploadProgress,
          });
      await uploadRequest;

      /**
       * HIT 3 -> Post request to save the data to DDM...
       */
      const videoDataPostPayload = {
        key: keyFileName,
        type: 'three_sixty',
      };
      const videoDataPostUrl = makeV2APIURL('projects', projectId, 'videos');
      const videoDataPostResponse = await axios.post(videoDataPostUrl, videoDataPostPayload, {
        headers: header,
      });
      const { data } = videoDataPostResponse.data;

      /**
       * HIT 4 -> Final Post request to say that every thing is ready, trigger airflow...
       */
      const finalSubmitUrl = makeV2APIURL('videos', data.id, 'submit_vslam');
      await axios.post(finalSubmitUrl, {}, { headers: header });
      setGeoreferencingMapDetails({
        isVslamVideoUploading: false,
        uploadVslamVideoProgressCount: 0,
      });
      dispatch(FinishPostPhoto());
      dispatch(GetDroneVideos({ projectId }));
      dispatch(FinishUploadPhotos());
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.VSLAM_VIDEO_UPLOAD_SUCCESS }));
    } catch (e) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.VSLAM_VIDEO_UPLOAD_FAIL }));
    }
  }

  return { upload360IndoorData };
}
