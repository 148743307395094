import { Language } from '^/types';

export default {
  account: {
    title: {
      [Language.EN_US]: 'Account',
      [Language.KO_KR]: '계정',
    },
    profile: {
      title: {
        [Language.EN_US]: 'Profile',
        [Language.KO_KR]: '프로필',
      },
      email: {
        [Language.EN_US]: 'Email',
        [Language.KO_KR]: '이메일',
      },
      name: {
        [Language.EN_US]: 'Name',
        [Language.KO_KR]: '이름',
      },
      phoneNumber: {
        [Language.EN_US]: 'Phone Number',
        [Language.KO_KR]: '전화번호',
      },
    },
    affiliation: {
      title: {
        [Language.EN_US]: 'Affiliation',
        [Language.KO_KR]: '소속',
      },
      organization: {
        [Language.EN_US]: 'Organization',
        [Language.KO_KR]: '조직',
      },
      purpose: {
        [Language.EN_US]: 'Purpose',
        [Language.KO_KR]: '목적',
      },
    },
    session: {
      title: {
        [Language.EN_US]: 'Session',
        [Language.KO_KR]: '세션',
      },
      signOut: {
        [Language.EN_US]: 'Sign out',
        [Language.KO_KR]: '로그아웃',
      },
    },
  },
  security: {
    title: {
      [Language.EN_US]: 'Security',
      [Language.KO_KR]: '보안',
    },
    lastChanged: {
      [Language.EN_US]: 'Last Changed',
      [Language.KO_KR]: '마지막 변경일',
    },
    password: {
      [Language.EN_US]: 'Password',
      [Language.KO_KR]: '비밀번호',
    },
    deleteAccount: {
      [Language.EN_US]: 'Delete Account',
      [Language.KO_KR]: '계정 탈퇴',
    },
  },
  plan: {
    subscription: {
      title: {
        [Language.EN_US]: 'Subscription',
        [Language.KO_KR]: '구독',
      },
      currentPlan: {
        [Language.EN_US]: 'Current Plan',
        [Language.KO_KR]: '현재 플랜',
      },
      basic: {
        [Language.EN_US]: 'Basic',
        [Language.KO_KR]: '기본의',
      },
      changePlan: {
        [Language.EN_US]: 'Change Plan',
        [Language.KO_KR]: '플랜 변경',
      },
    },
  },
  settings: {
    locale: {
      title: {
        [Language.EN_US]: 'Locale',
        [Language.KO_KR]: '언어',
      },
      country: {
        [Language.EN_US]: 'Country',
        [Language.KO_KR]: '국가',
      },
      language: {
        [Language.EN_US]: 'Language',
        [Language.KO_KR]: '언어',
      },
    },
  },
  purposeOptions: {
    construction: {
      [Language.KO_KR]: '건설',
      [Language.EN_US]: 'Construction',
    },
    survey: {
      [Language.KO_KR]: '측량',
      [Language.EN_US]: 'Survey',
    },
    urbanPlanning: {
      [Language.KO_KR]: '도시계획',
      [Language.EN_US]: 'Urban Planning',
    },
    disasterResponse: {
      [Language.KO_KR]: '재난재해 대비/대책',
      [Language.EN_US]: 'Disaster Response',
    },
    inspection: {
      [Language.KO_KR]: '시설물 점검',
      [Language.EN_US]: 'Inspection',
    },
    droneService: {
      [Language.KO_KR]: '드론 서비스업',
      [Language.EN_US]: 'Drone Service',
    },
    others: {
      [Language.KO_KR]: '기타',
      [Language.EN_US]: 'Others',
    },
    default: {
      [Language.KO_KR]: '용도를 선택해주세요',
      [Language.EN_US]: 'Select your purpose',
    },
  },
};
